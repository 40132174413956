import React from 'react';
import NotAllowedError from './NotAllowedError';

interface AuthorizedRouteProps {
  children: React.ReactElement;
  isAuthorized: boolean;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({ children, isAuthorized }) => {
  if (!isAuthorized) {
    return <NotAllowedError />;
  }

  return <>{children}</>;
};

export default AuthorizedRoute;
