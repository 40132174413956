import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  export: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      boxShadow: 'none'
    },
    marginLeft: 12
  }
}));
