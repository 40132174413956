import { FileType } from './UploadFile/types';
import { v4 as uuid } from 'uuid';
import { Statuses } from 'store';
import { MAX_YEAR, MIN_YEAR } from 'store/src/shared/utils/quarters.utils';

export const processSelectedFiles = (existingFiles: FileType[] = [], newSelectedFiles: File[]) => {
  const processedFiles: FileType[] = [...existingFiles];

  for (const file of newSelectedFiles) {
    switch (file?.type) {
      case 'application/pdf':
        const extractedPeriodFromFileName = extractPeriodFromFileName(file.name);
        const newFile = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          id: uuid(),
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          language: 'en-us',
          period: extractedPeriodFromFileName,
          status: Statuses.Pending,
          file
        };
        processedFiles.push(newFile);
        break;
    }
  }
  return processedFiles;
};

export const updateFilePeriod = (files: FileType[], fileId: string, newPeriodValue: string) => {
  const newFiles = [...files];
  const index = newFiles.findIndex((file) => file.id === fileId);
  newFiles[index].period = newPeriodValue;
  return newFiles;
};

export const removeFileFromSelectedFiles = (files: FileType[], fileId: string) => {
  const newFiles = [...files];
  const index = newFiles.findIndex((file) => file.id === fileId);
  newFiles.splice(index, 1);
  return newFiles;
};

export const updateFileLanguage = (files: FileType[], fileId: string, newLanguage: string) => {
  const newFiles = [...files];
  const index = newFiles.findIndex((file) => file.id === fileId);
  newFiles[index].language = newLanguage;
  return newFiles;
};

/**
 * Extract a valid period value form the filename
 * return empty string if there is no valid period in the filename
 *
 * input: 2019Q1.pdf
 * output: 2019Q1
 *
 */
export const extractPeriodFromFileName = (fileNameWithExtension: string) => {
  try {
    const filename = fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));

    // Verify period
    const year = +filename.substr(0, 4);
    const quarter = filename.substr(4);

    // Length should be 1 or 2 ex: Q1, A
    const isQuarterValid = [1, 2].includes(quarter.length);
    const isYearValid = year > MIN_YEAR && year < MAX_YEAR;

    if (!isQuarterValid || !isYearValid) {
      throw Error('Invalid filename schema');
    }

    return filename;
  } catch (e) {
    // Something went wrong during extraction fo the period from the filename
    console.error(e);
    return '';
  }
};

export const getDateFromQuarter = (quarter: string) => {
  const year = quarter.substr(0, 4);
  const _Q = quarter.substr(4);
  switch (_Q) {
    case 'Q1':
      return new Date(`${year}-03-31`);
    case 'Q2':
      return new Date(`${year}-06-30`);
    case 'Q3':
      return new Date(`${year}-09-30`);
    case 'Q4':
      return new Date(`${year}-12-31`);
  }
  throw Error('Invalid Quarter');
};

export const errorMsgResolver = (e: string | { message: string }) => {
  if (typeof e === 'string') {
    return e;
  } else if (typeof e === 'object') {
    return (e as { message: string })?.message;
  } else {
    return 'Something went wrong';
  }
};
