import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import useStyles from '../UploadViaLink/styles';
import { PeriodInput } from 'store';
import { PeriodRange } from '../UploadViaLink/types';

interface PeriodRowProps {
  periodRange: PeriodRange;
  onRemove: () => void;
  onChange: (newPeriod: PeriodRange) => void;
}
export const PeriodRangeRow = ({ periodRange, onChange, onRemove }: PeriodRowProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.periodRangeContainer}>
      <PeriodInput
        onPeriodChange={(newValue) => onChange({ ...periodRange, start: newValue })}
        value={periodRange.start ?? ''}
        label={'Start Period'}
      />
      <PeriodInput
        onPeriodChange={(newValue) => onChange({ ...periodRange, end: newValue })}
        value={periodRange.end ?? ''}
        label={'End Period'}
        minQuarter={periodRange.start}
      />
      <Box display="flex" alignItems="center" justifyContent="end">
        <CloseIcon onClick={onRemove} className={classes.closeIcon} />
      </Box>
    </Box>
  );
};
