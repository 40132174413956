import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    border: 'black solid 1px',
    padding: 12,
    borderRadius: 20,
    height: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    cursor: 'pointer'
  }
});
