import React, { useState } from 'react';
import { confirm, EntityGrid } from 'store';
import DocumentsActions from 'components/Documents/DocumentsActions';
import UploadFile from 'components/Documents/UploadFile';
import UploadViaLink from 'components/Documents/UploadViaLink';
import { getDocumentColDef } from './constants';
import { DocumentType } from './types';
import { deleteDocument } from './services';
import EditDocument from './EditDocument';
import useAuthorization from '../../hooks/useAuthorization';
import { PermissionKeys } from '../../config/permission-keys';

const Documents: React.FC = () => {
  const [isUploadViaLinkOpen, setUploadViaLinkOpen] = useState<boolean>(false);
  const [isUploadFileOpen, setUploadFileOpen] = useState<boolean>(false);
  const [activeDocument, setActiveDocument] = useState<DocumentType | undefined>();
  const canDelete = useAuthorization(PermissionKeys.CAN_DELETE_DOCUMENT);
  const canEdit = useAuthorization(PermissionKeys.CAN_EDIT_DOCUMENT);

  const onDeleteDocument = (row: DocumentType) => {
    confirm('Delete Confirmation', 'Are you sure you want to delete this document ?', () => {
      deleteDocument(row.id).then((res) => {
        console.log(res);
      });
    });
  };

  const onEditDocument = (data: DocumentType) => {
    setActiveDocument(data);
  };

  return (
    <>
      <EntityGrid
        actionContent={
          <DocumentsActions
            triggerUploadViaLink={() => setUploadViaLinkOpen(!isUploadViaLinkOpen)}
            triggerUploadFile={() => setUploadFileOpen(!isUploadFileOpen)}
          />
        }
        entityName="document"
        rows={[]}
        columns={getDocumentColDef(onDeleteDocument, onEditDocument, canEdit, canDelete)}
        searchableColumns={['id']}
        placeholder="Search by ID..."
      />
      <UploadFile isOpen={isUploadFileOpen} handleClose={() => setUploadFileOpen(false)} />
      <UploadViaLink isOpen={isUploadViaLinkOpen} handleClose={() => setUploadViaLinkOpen(false)} />
      <EditDocument
        isOpen={!!activeDocument}
        onClose={() => setActiveDocument(undefined)}
        selectedDocument={activeDocument}
      />
    </>
  );
};

export default Documents;
