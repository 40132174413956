export const GridConfig = {
  pageSize: 10,
  rowsPerPageOptions: [10]
};

export const TableOperatorsMap: { [key: string]: string } = {
  contains: 'icontains',
  equals: 'iexact',
  starsWith: '',
  endsWith: '',
  isEmpty: '',
  isNotEmpty: '',
  is: ''
};

export const CANCELED_ERROR = '460';
