import { UserType } from '../types';

export enum ActionTypes {
  GET_USER = 'GET_USER',
  UPDATE_USER = 'UPDATE_USER',
  TRIGGER_LOADING = 'TRIGGER_LOADING'
}

export interface getUserAction {
  type: ActionTypes.GET_USER;
  payload: null;
}

export interface triggerLoadingActionType {
  type: ActionTypes.TRIGGER_LOADING;
  payload: boolean;
}

export interface updateUserActionType {
  type: ActionTypes.UPDATE_USER;
  payload: UserType;
}

export type ActionType = getUserAction | triggerLoadingActionType | updateUserActionType;
