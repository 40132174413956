import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import useStyles from './styles';
import AppLogo from '../AppLogo';
import { useFormik } from 'formik';
import { LoginPayloadType } from './types';
import LoginFormSchema from './LoginFormSchema';
import * as Services from './services';
import { FormikType } from '../shared/types';
import { isLoggedIn } from 'core/modules/auth.utils';

interface LoginProps {
  title: string;
  redirectTo: string;
}

const LoginPage: React.FC<LoginProps> = ({ title, redirectTo }) => {
  const classes = useStyles();
  const [error, setError] = useState<string | null>('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn().isAuthenticated) {
      history.push({ pathname: redirectTo });
    }
  }, [history, redirectTo]);

  const formik: FormikType<LoginPayloadType> = useFormik<LoginPayloadType>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginFormSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError(null);

      Services.login(
        values,
        () => {
          history.push({ pathname: redirectTo });
          setLoading(false);
        },
        (e: string) => {
          setError(e);
          setLoading(false);
        }
      );
    }
  });

  return (
    <Container component="main" maxWidth="xs" className="login-container">
      <CssBaseline />

      <div className={classes.paper}>
        <AppLogo title={title} />

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box m={1}>{error && <Alert severity="error">{error}</Alert>}</Box>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            type="email"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email && formik.touched.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            error={Boolean(formik.errors.password && formik.touched.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLoading ? (
              <CircularProgress size="24px" color="secondary" className={'spinner'} />
            ) : (
              'Sign In'
            )}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default LoginPage;
