import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from 'ui-kit';
import { ConfigurationType } from './types';
import useStyles from './styles';
import Box from '@material-ui/core/Box';
import { isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { createNewConfiguration, updateConfiguration } from './services';
import { errorMsgResolver } from '../Documents/utils';
import { notify } from 'store';

interface ConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  activeConfiguration: ConfigurationType | undefined;
}

export const ConfigurationModal = ({
  isOpen,
  onClose,
  activeConfiguration
}: ConfigurationModalProps) => {
  const [key, setKey] = useState<string>('');
  const [isKeyTouched, setKeyTouched] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [isValueTouched, setValueTouched] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (activeConfiguration?.key) {
      setKey(activeConfiguration.key);
    } else {
      setKey('');
      setKeyTouched(false);
    }
    if (activeConfiguration?.value) {
      setValue(activeConfiguration.value);
    } else {
      setValue('');
      setValueTouched(false);
    }
  }, [activeConfiguration]);

  useEffect(() => {
    if (!isOpen) {
      setKey('');
      setValue('');
      setValueTouched(false);
      setKeyTouched(false);
    }
  }, [isOpen]);

  const isSaveDisabled = useMemo(() => {
    return isEmpty(key) || isEmpty(value);
  }, [key, value]);

  const onSave = async () => {
    const isEdit = !isEmpty(activeConfiguration);
    setLoading(true);

    const requestPayload = {
      ...(activeConfiguration || {}),
      key,
      value
    };

    try {
      const api = isEdit ? updateConfiguration : createNewConfiguration;
      const resp = await api(requestPayload as ConfigurationType);
      if (resp.status !== 200) {
        notify({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          message: errorMsgResolver((resp.data as any).details),
          severity: 'error',
          open: true
        });
      } else {
        notify({
          message: 'Configuration saved Successfully',
          severity: 'success',
          open: true
        });
        onClose();
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title="Configuration"
      handleClose={onClose}
      minHeight={'400px'}
      isSaveDisabled={isSaveDisabled}
      handleOk={onSave}
      isLoading={isLoading}
    >
      <Box className={classes.container}>
        <TextField
          label="Key"
          fullWidth={true}
          placeholder="Configuration Key"
          value={key}
          onChange={(e) => setKey(e.target.value?.trim())}
          variant="outlined"
          onBlur={() => setKeyTouched(true)}
          error={isEmpty(key) && isKeyTouched}
          helperText={isEmpty(key) && isKeyTouched ? "Key can't be empty" : undefined}
        />
        <TextField
          className={classes.configurationValue}
          label="Value"
          fullWidth={true}
          placeholder="Configuration Value"
          value={value}
          onChange={(e) => setValue(e.target.value?.trim())}
          variant="outlined"
          onBlur={() => setValueTouched(true)}
          error={isEmpty(value) && isValueTouched}
          helperText={isEmpty(value) && isValueTouched ? "value can't be empty" : undefined}
          multiline={true}
        />
      </Box>
    </Dialog>
  );
};
