import { CompanyFormStatus, IndustryType } from '../types';
import { CompanyType, EditCompanyType } from 'store';
import { isEqual } from 'lodash';
import { OptionType } from 'ui-kit';

export const getIndustryOption = (
  industry: number | undefined,
  industries: IndustryType[],
  formStatus: keyof CompanyFormStatus | undefined
) => {
  if (formStatus === 'ADD') {
    return undefined;
  }
  return industries?.find((_industry) => _industry.id == industry);
};

export const getChangedValues = (values: CompanyType, initialValues: CompanyType) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = !isEqual(initialValues[key as keyof CompanyType], value);
    if (hasChanged) {
      acc[key as keyof CompanyType] = value;
    }
    return acc;
  }, {} as Partial<CompanyType>);
};
export const getExtraValues = (
  industries: IndustryType[],
  formStatus?: string,
  activeCompany?: CompanyType
): Partial<CompanyType> | undefined => {
  if (formStatus === 'EDIT' && activeCompany) {
    const selectedIndustry = getIndustryOption(activeCompany?.industry_fk, industries, formStatus);
    const selectedIndustryOption = selectedIndustry
      ? { label: selectedIndustry.name, value: `${selectedIndustry.id}` }
      : undefined;
    return {
      industry: selectedIndustryOption,
      sector: selectedIndustry?.sector_name
    };
  }
};

export const getEditCompanyPayload = (companyType: EditCompanyType): EditCompanyType => {
  const companyIndustry = companyType?.industry as OptionType;
  if (companyIndustry) {
    delete companyType?.industry;
  }
  const reqBody: EditCompanyType = {
    ...companyType
  };
  if (companyIndustry) {
    reqBody.industry_fk = (companyIndustry?.value as unknown) as number;
  }
  reqBody.identifiers = companyType.identifiers;
  return reqBody;
};
