import React from 'react';
import { EntityGrid } from 'store';

import { LogsColDef } from './constants';
import { EntityGridProps } from 'store/src/EntityGrid/types';

const LogsTable = () => {
  return (
    <>
      <EntityGrid
        actionContent={<React.Fragment />}
        entityName="crawler_job"
        rows={[]}
        columns={LogsColDef}
      />
    </>
  );
};

export default LogsTable;
