import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    },
    configurationValue: {
      marginTop: '20px',
      '& textarea': {
        minHeight: '150px'
      }
    }
  })
);
