import React from 'react';
import { GridActionsProps } from '../types';
import useStyles from '../../TableRowActions/styles';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

const Actions: React.FC<GridActionsProps> = ({ actionContent }) => {
  return <div>{actionContent}</div>;
};

export const DeleteButton = ({ onDelete }: { onDelete: () => void }) => {
  const classes = useStyles();
  return (
    <IconButton color="primary" aria-label="delete" component="span" className={classes.actionBtn}>
      <DeleteIcon className={classes.deleteIcon} onClick={onDelete} />
    </IconButton>
  );
};

export default Actions;
