import { range } from 'lodash';

export const MIN_YEAR = 1995;
export const MAX_YEAR = 2026;

export const getQuarters = (minQuarter?: string) => {
  let startYear = MIN_YEAR;
  let endYear = new Date().getFullYear() + 1;
  const quarterOptions = [];
  let minQuarterFound = false;
  while (endYear >= startYear) {
    for (let i = 5; i >= 1; i--) {
      const quarter = `${endYear}Q${i === 5 ? 'A' : i}`;
      if (quarter === minQuarter) {
        minQuarterFound = true;
        break;
      }
      quarterOptions.push({ label: quarter, value: quarter });
    }
    if (minQuarterFound) {
      break;
    }
    endYear--;
  }
  return quarterOptions;
};
