import {
  ActionTypes,
  getUserAction,
  triggerLoadingActionType,
  updateUserActionType
} from './actionTypes';
import { UserType } from '../types';

export function getUser(): getUserAction {
  return {
    type: ActionTypes.GET_USER,
    payload: null
  };
}

export function updateUser(user: UserType): updateUserActionType {
  return {
    type: ActionTypes.UPDATE_USER,
    payload: user
  };
}

export function triggerLoading(isLoading: boolean): triggerLoadingActionType {
  return {
    type: ActionTypes.TRIGGER_LOADING,
    payload: isLoading
  };
}
