export enum Statuses {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Success = 'SUCCEEDED',
  InProgress = 'INPROGRESS'
}

export const StatusesColors = {
  [Statuses.Pending]: {
    textColor: '#E38834',
    bgColor: '#FCF5EF'
  },
  [Statuses.Success]: {
    textColor: '#66A856',
    bgColor: '#E3F3DE'
  },
  [Statuses.Failed]: {
    textColor: '#FB4E4E',
    bgColor: '#FFEEEE'
  },
  [Statuses.InProgress]: {
    textColor: '#1979FF',
    bgColor: '#F0F9FF'
  }
};
