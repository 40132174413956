import { GenerateStatus, ModelType } from './types';

export const TYPE_COLOR_MAP = {
  [ModelType.finalized]: 'green',
  [ModelType.clientView]: 'orange',
  [ModelType.updateReady]: 'cyan',
  [ModelType.annualReady]: 'blue',
  [ModelType.reviewed]: 'yellow',
  [ModelType.apiSnapshot]: 'red',
  [ModelType.qqqqFySnapshot]: '#00ABB3'
};

export const TYPE_TITLE_MAP = {
  [ModelType.finalized]: 'Finalized',
  [ModelType.clientView]: 'In Production',
  [ModelType.updateReady]: 'Update available',
  [ModelType.annualReady]: 'Preprocess annual available',
  [ModelType.reviewed]: 'Reviewed',
  [ModelType.apiSnapshot]: 'API Snapshot',
  [ModelType.qqqqFySnapshot]: 'QQQQFY Snapshot'
};

export const GENERATE_STATUS_COLOR_MAP = {
  [GenerateStatus.COMPLETED]: 'green',
  [GenerateStatus.FAILED]: 'red',
  [GenerateStatus.INPROGRESS]: 'orange',
  [GenerateStatus.QUEUED]: 'blue',
  [GenerateStatus.STARTED]: 'cyan'
};
