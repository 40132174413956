import React, { useState } from 'react';

import { AuthorizationGard, confirm, EntityGrid, notify } from 'store';
import { getApiKeysColDef } from './constants';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import useAuthorization from '../../hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';
import useStyles from '../Companies/CompaniesActions/styles';
import { GenerateAPIKeyModal } from './GenerateAPIKeyModal';
import * as services from 'components/APIKeys/services';
import { errorMsgResolver } from 'components/Documents/utils';

const ApiKeys: React.FC = () => {
  const canAddApiKey = useAuthorization(PermissionKeys.CAN_ADD_API_KEY);
  const [isGenerateAPIKeyModalOpened, setIsGenerateAPIKeyModalOpened] = useState(false);
  const classes = useStyles();

  const onDelete = (apiKeyId: number) => {
    confirm('Delete Confirmation', 'Are you sure you want to delete this API Key ?', () => {
      services
        .deleteAPIKey(apiKeyId)
        .then((result) => {
          if (result) {
            notify({
              message: 'API Key Deleted !',
              severity: 'success',
              open: true
            });
          }
        })
        .catch((error) => {
          notify({
            message: errorMsgResolver(error as string),
            severity: 'error',
            open: true
          });
        });
    });
  };

  const actions = (
    <Box display="flex">
      <AuthorizationGard isAuthorized={canAddApiKey}>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          startIcon={<AddBoxOutlined />}
          onClick={() => setIsGenerateAPIKeyModalOpened(true)}
          disabled={!canAddApiKey}
        >
          Api Key
        </Button>
      </AuthorizationGard>
      <Box m={1} />
    </Box>
  );

  return (
    <>
      <GenerateAPIKeyModal
        isOpen={isGenerateAPIKeyModalOpened}
        onClose={() => setIsGenerateAPIKeyModalOpened(false)}
      />
      <EntityGrid
        entityName="api_key"
        actionContent={actions}
        rows={[]}
        columns={getApiKeysColDef(canAddApiKey, onDelete)}
        searchableColumns={['user__email']}
        placeholder="Search by E-mail..."
      />
    </>
  );
};

export default ApiKeys;
