import { CompanyType } from 'store';

export enum ProductType {
  DALOOPA = 'daloopa',
  DALOOPA_PLUS = 'daloopa_plus',
  DALOOPA_PLUS_LTD = 'daloopa_plus_time_ltd'
}

export enum UserStatus {
  FREE = 'FREE',
  SOFT_BLOCKED = 'SOFT_BLOCKED',
  HARD_BLOCKED = 'HARD_BLOCKED'
}

export enum UserTier {
  TRIAL = 'trial',
  ENTERPRISE = 'enterprise',
  FREE = 'free',
  STUDENT = 'student'
}

export enum UserType {
  PAYING = 'paying',
  OVERAGE = 'overage',
  SUPPORT = 'support',
  PARTNER = 'partner',
  INTERNAL = 'internal',
  ENTERPRISE_TRIAL = 'enterprise_trial',
  TRANSITION = 'transition'
}

export interface User {
  id: number;
  username: string;
  email: string;
  tier: UserTier;
  user_type: UserType;
  groups: string[];
  cognito_username: string;
  date_joined: string;
  is_analyst: boolean;
  is_active: boolean;
  disabled_by: string;
  product_type?: string;
  marketplace_status?: string;
  daily_download_limit?: string;
  total_download_limit?: string;
  created_by: string;
}

export interface UserCompanyAccesses {
  id: number;
  company: CompanyType;
  accessMode: string;
}

export enum AccessMode {
  NO_ACCESS = 'NO_ACCESS',
  PURCHASED = 'PURCHASED',
  SUBSCRIBED = 'SUBSCRIBED',
  REQUESTED = 'REQUESTED',
  PRIVATE_ACCESS = 'PRIVATE_ACCESS'
}
