import React, { useMemo, useState } from 'react';
import { Dialog } from 'ui-kit';
import { IndustryModelForm } from './IndustryModelForm';
import { useFormik } from 'formik';
import { CreateIndustryModalType, IndustryModal } from '../types';
import { CreateIndustryModelFormSchema, EditIndustryModelFormSchema } from './schema';
import { createIndustryModal, editIndustryModel } from '../services';
import { isEmpty } from 'lodash';
import { notify } from 'store';
import { useSelector } from 'react-redux';
import { selectIndustries } from '../../Companies/state/selectors';

interface UploadIndustryModalProps {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  currentIndustryModel?: IndustryModal;
  mode: 'edit' | 'create';
}

export const IndustryModelModal = ({
  isOpen,
  handleClose,
  onSuccess,
  currentIndustryModel,
  mode
}: UploadIndustryModalProps) => {
  const industries = useSelector(selectIndustries);

  const selectedIndustry = useMemo(() => {
    if (mode === 'edit' && currentIndustryModel && currentIndustryModel.industry) {
      const selected = industries.find(
        (industry) =>
          industry.sector_name.toLowerCase() === currentIndustryModel?.industry?.toLowerCase()
      );
      if (selected) {
        return { value: `${selected.sector}`, label: selected.sector_name };
      }
      return undefined;
    }
    return undefined;
  }, [currentIndustryModel, mode]);

  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik<CreateIndustryModalType>({
    validationSchema:
      mode === 'create' ? CreateIndustryModelFormSchema : EditIndustryModelFormSchema,
    initialValues: {
      file: undefined,
      description: currentIndustryModel?.description,
      industry: selectedIndustry,
      latestQuarter: currentIndustryModel?.latestQuarter,
      active: currentIndustryModel?.active ?? true
    },
    onSubmit: async (values) => {
      let success = false;
      if (values.industry && values.latestQuarter) {
        setIsLoading(true);
        if (mode === 'create') {
          const id = await createIndustryModal(
            values.industry?.label,
            values.active,
            values.latestQuarter,
            values.file,
            values.description
          );
          success = !isEmpty(id);
        } else if (mode === 'edit' && currentIndustryModel) {
          success = await editIndustryModel(
            currentIndustryModel.id,
            values.active,
            values.industry.label,
            values.latestQuarter,
            values.description,
            values.file
          );
        }

        setIsLoading(false);
        if (success) {
          onSuccess();
          cleanUpAndClose();
          notify({
            message: `industry model ${mode === 'edit' ? 'edited' : 'created'}  successfully`,
            severity: 'success',
            open: true
          });
        } else {
          notify({
            message: `failed to ${mode === 'edit' ? 'edited' : 'created'} industry model`,
            severity: 'error',
            open: true
          });
        }
      }
    }
  });

  const cleanUpAndClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`${mode === 'edit' ? 'Edit' : 'Create'} Industry Model`}
      handleClose={cleanUpAndClose}
      width={'sm'}
      hideDialogActions={false}
      minHeight={'30vh'}
      isLoading={isLoading}
      isSaveDisabled={!formik.isValid}
      handleOk={formik.submitForm}
    >
      <IndustryModelForm formik={formik} currentIndustryModel={currentIndustryModel} mode={mode} />
    </Dialog>
  );
};
