import React from 'react';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { Chip, OptionType } from 'ui-kit';
import { StatusesColors, Statuses, PeriodInput, Base64Utils } from 'store';
import { InputBase } from '@material-ui/core';

export interface FileType {
  id: string;
  period?: string;
  status?: string;
  name: string;
  type: string;
  lastModified?: number;
  size?: number;
  file?: File;
  language?: string;
}

export interface UploadFilesFormType {
  ticker?: OptionType;
  files?: FileType[];
}

export type onRemoveFileType = (id: string) => void;

const baseProperties = {
  sortable: false,
  filterable: false,
  resizable: false
};

export const getFilesCols: (
  onRemoveFile: onRemoveFileType,
  onPeriodChange: (id: string, newValue: string) => void,
  onLanguageChange: (id: string, lang: string) => void
) => GridColumns = (onRemoveFile, onPeriodChange, onLanguageChange) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    ...baseProperties
  },
  {
    field: 'period',
    headerName: 'Period',
    width: 150,
    ...baseProperties,
    renderCell: (params: GridCellParams) => (
      <PeriodInput
        onPeriodChange={(newValue) => onPeriodChange(params.id.toString(), newValue)}
        value={params.value as string}
      />
    )
  },
  {
    field: 'language',
    header: 'Language',
    width: 150,
    ...baseProperties,
    renderCell: (params: GridCellParams) => (
      <InputBase
        value={params.value as string}
        onChange={(e) => onLanguageChange(params.id.toString(), e.target.value)}
      />
    )
  },
  {
    field: 'size',
    headerName: 'Size',
    width: 100,
    ...baseProperties,
    renderCell: (params) => (
      <span>{Base64Utils.bytesToSize(parseInt(params?.value?.toString() ?? ''))}</span>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    ...baseProperties,
    renderCell: (params) => {
      const value = params.value?.toString() ?? Statuses.Pending.toString();
      const colors = StatusesColors[value as Statuses];
      return <Chip text={value} textColor={colors.textColor} bgColor={colors.bgColor} />;
    }
  },
  {
    field: '',
    headerName: 'Action',
    renderCell: (params) => (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CloseIcon cursor="pointer" onClick={() => onRemoveFile(params.id.toString())} />
      </Box>
    ),
    width: 100,
    ...baseProperties
  }
];

export enum FileUploadStatuses {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  pending = 'PENDING'
}
