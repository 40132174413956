import { createSelector } from 'reselect';
import { RootStateType } from 'store/root-reducer';
import { DocumentsStateType } from '../types';

export const selectSelectedCompany = createSelector(
  (state: RootStateType) => state.documents,
  (documents: DocumentsStateType) => documents.selectedCompany
);

export const selectDocumentLinks = createSelector(
  (state: RootStateType) => state.documents,
  (documents) => documents.documentLinks
);

export const selectDocumentLinkAtIndex = (index: number) =>
  createSelector(
    (state: RootStateType) => state.documents,
    (documents) => documents.documentLinks[index]
  );

export const selectIsLoading = createSelector(
  (state: RootStateType) => state.documents,
  (documents: DocumentsStateType) => documents.isLoading
);

export const selectError = createSelector(
  (state: RootStateType) => state.documents,
  (documents) => documents.error
);
