import React from 'react';
import { GridColumns, GridRowData } from '@material-ui/data-grid';
import TableRowActions from 'store/src/TableRowActions';
import { InsertLink } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

export const getDocumentColDef: (
  onDeleteRow: (row: GridRowData) => void,
  onEditRow: (row: GridRowData) => void,
  canEdit: boolean,
  canDelete: boolean
) => GridColumns = (onDeleteRow, onEditRow, canEdit, canDelete) => [
  { field: 'id', headerName: 'ID', width: 150, filterable: false },
  { field: 'cik', headerName: 'cik', width: 150, filterable: false },
  {
    field: 'filing_type',
    headerName: 'Type',
    width: 150,
    filterable: false
  },
  {
    field: 'reported_period',
    headerName: 'Period',
    width: 150,
    filterable: false
  },
  {
    field: 'url',
    headerName: 'Url',
    width: 150,
    filterable: false,
    renderCell: (params) => {
      const url = params.value as string;
      return (
        <IconButton onClick={() => window.open(url, '_blank')}>
          <InsertLink />
        </IconButton>
      );
    }
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params) => (
      <TableRowActions
        onEditRow={() => onEditRow(params.row)}
        onDeleteRow={() => onDeleteRow(params.row)}
        canEdit={canEdit}
        canDelete={canDelete}
      />
    ),
    width: 200,
    filterable: false,
    align: 'right',
    headerAlign: 'right'
  }
];
