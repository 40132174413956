import { Axios } from 'core';
import { GetTickersType } from './types';

export const FETCH_TICKERS = '/golden_gate/company';

type Filters = {
  withLiveModels?: boolean;
};

export async function getTickers(
  tickerName: string,
  { withLiveModels }: Filters
): Promise<GetTickersType> {
  const queryParams = new URLSearchParams();
  const filters: {
    ticker__istartswith: string;
    has_live_excel_model?: boolean;
  } = {
    ticker__istartswith: tickerName
  };

  if (withLiveModels) {
    filters['has_live_excel_model'] = true;
  }

  queryParams.append('filters', JSON.stringify(filters));

  queryParams.append('page', '1');
  queryParams.append('page_size', '10');
  queryParams.append('order', 'ticker');

  return await Axios.get(`${FETCH_TICKERS}?${queryParams.toString()}`);
}
