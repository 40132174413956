import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

interface ChipProps {
  text: string | undefined;
  textColor: string;
  bgColor: string;
}

const Chip: React.FC<ChipProps> = ({ text, textColor, bgColor }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      style={{ color: textColor, backgroundColor: bgColor, border: `1px solid ${textColor}` }}
    >
      <Typography>{text}</Typography>
    </Box>
  );
};

export default Chip;
