import {
  ActionTypes,
  AddDocumentLinksActionType,
  RemoveDocumentLinksActionType,
  ResetLinksFormActionType,
  SubmitUploadLinksActionType,
  TriggerLoadingActionType,
  UpdateSelectedCompanyActionType,
  UpdateDocumentLinkActionType
} from './actionTypes';
import { UploadLinkEntry } from '../UploadViaLink/types';
import { UploadLinksReqType } from '../types';
import { MixpanelContext } from '../../../mixpanel/mixpanelContext';

export function updateSelectedCompanyAction(
  ticker: string,
  cik: string,
  companyId: string
): UpdateSelectedCompanyActionType {
  return {
    type: ActionTypes.UPDATE_SELECTED_COMPANY,
    payload: { ticker, cik, id: companyId }
  };
}

export function addDocumentLinksAction(
  numberOfDocumentsToBeAdded: number
): AddDocumentLinksActionType {
  return {
    type: ActionTypes.ADD_DOCUMENT_LINKS,
    payload: numberOfDocumentsToBeAdded
  };
}

export function removeDocumentLinkAction(indexOfDocument: number): RemoveDocumentLinksActionType {
  return {
    type: ActionTypes.REMOVE_DOCUMENT_LINK,
    payload: indexOfDocument
  };
}

export function updateDocumentLinkData(
  linkData: UploadLinkEntry,
  index: number
): UpdateDocumentLinkActionType {
  return {
    type: ActionTypes.UPDATE_DOCUMENT_LINK_ENTITY,
    payload: {
      data: linkData,
      index
    }
  };
}

export function resetLinksFormAction(): ResetLinksFormActionType {
  return {
    type: ActionTypes.RESET_LINKS,
    payload: undefined
  };
}

export function triggerLoadingAction(isLoading: boolean): TriggerLoadingActionType {
  return {
    type: ActionTypes.TRIGGER_LOADING,
    payload: isLoading
  };
}

export function submitUploadLinks(
  linksData: UploadLinksReqType,
  mixpanel: MixpanelContext
): SubmitUploadLinksActionType {
  return {
    type: ActionTypes.SUBMIT_UPLOAD_LINKS,
    payload: { request: linksData, mixpanel }
  };
}
