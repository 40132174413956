import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { useAction } from '../hooks/useAction';

const AddRowsInput: React.FC = () => {
  const [numOfRowsToBeAdded, setNumOfRowsToBeAdded] = useState<number>(1);

  const classes = useStyles();
  const { addDocumentLinksAction } = useAction();

  return (
    <>
      <InputBase
        value={numOfRowsToBeAdded}
        onChange={(e) => setNumOfRowsToBeAdded(+e.target.value)}
        className={classes.linkInput}
        id="outlined-number"
        type="number"
      />
      <Button
        className={classes.addRowsButton}
        variant="contained"
        color="primary"
        onClick={() => addDocumentLinksAction(numOfRowsToBeAdded)}
      >
        Add
      </Button>
    </>
  );
};

export default AddRowsInput;
