import React from 'react';
import { SidebarProps } from './types';
import Box from '@material-ui/core/Box';
import CreativeImg from '../../assets/icons/creative.png';
import useStyles from './styles';
import { NavLink } from 'react-router-dom';

const Sidebar: React.FC<SidebarProps> = ({ items, imagePath }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box width="100%">
        {items.map((item) => (
          <NavLink
            key={item.title}
            to={item.to}
            className={classes.menuItemLink}
            activeStyle={{ backgroundColor: '#1979FF', color: 'white' }}
          >
            <Box className={classes.menuItem}>
              <Box>{item.icon}</Box>
              <Box className={classes.menuTitle}>{item.title}</Box>
            </Box>
          </NavLink>
        ))}
      </Box>
      <Box>
        <img src={imagePath ? imagePath : CreativeImg} width="280" height="208" />
      </Box>
    </Box>
  );
};

export default Sidebar;
