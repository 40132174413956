import TextField from '@material-ui/core/TextField';
import React, { useEffect, useMemo } from 'react';
import useStyles from '../style';
import { Box, Button } from '@material-ui/core';
import { CreateIndustryModalType, IndustryModal } from '../types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PeriodInput from 'store/src/PeriodInput';
import { useSelector } from 'react-redux';
import { selectIndustries } from '../../Companies/state/selectors';
import { ComboBox, OptionType } from 'ui-kit';
import { isEmpty, uniqBy } from 'lodash';
import { FormikType } from 'store';

interface CreateIndustryModelFormProps {
  formik: FormikType<CreateIndustryModalType>;
  currentIndustryModel?: IndustryModal;
  mode: 'edit' | 'create';
}
export const IndustryModelForm = ({ formik, mode }: CreateIndustryModelFormProps) => {
  const classes = useStyles();

  const industries = useSelector(selectIndustries);

  const sectorOptions: OptionType[] = useMemo(
    () =>
      uniqBy(
        industries
          ?.map((industry) => ({
            label: industry.sector_name,
            value: `${industry.sector}`
          }))
          .sort((sectorA, sectorB) => sectorA.label.localeCompare(sectorB.label)),
        (industry) => industry.label
      ),
    [industries]
  );

  useEffect(() => {
    if (formik && mode === 'create') {
      void formik.validateForm();
    }
  }, [mode]);

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.field}>
        <ComboBox
          name="industry"
          id="industry"
          placeholder="Select sector"
          value={formik.values.industry}
          setFieldValue={(field, value) => {
            const selectedValue = value as OptionType;
            const industryObject = industries.find(
              (industry) => industry.sector === +selectedValue.value
            );
            if (industryObject) {
              formik.setFieldValue('industry', {
                label: industryObject.sector_name,
                value: industryObject.sector
              });
            }
          }}
          options={sectorOptions}
        />
      </Box>
      <TextField
        label="Description"
        placeholder="Enter Model Description"
        className={classes.field}
        id={'description'}
        name={`description`}
        InputLabelProps={{
          shrink: true
        }}
        value={formik.values.description}
        onChange={formik.handleChange}
        variant="outlined"
        error={Boolean(formik.errors.description)}
        helperText={
          Boolean(formik.touched.description) && Boolean(formik.errors.description)
            ? 'description value is required'
            : undefined
        }
      />

      <PeriodInput
        value={formik.values.latestQuarter ?? ''}
        onPeriodChange={(period) => formik.setFieldValue('latestQuarter', period)}
      />

      <FormControlLabel
        className={classes.checkboxInput}
        control={
          <Checkbox
            checked={formik.values.active}
            color="primary"
            name="active"
            onChange={formik.handleChange}
          />
        }
        label="is Active"
      />
      <Box>
        <Button variant="contained" component="label" color={'primary'}>
          Choose Model
          <input
            type="file"
            accept=".xlsx, .xls"
            hidden
            onChange={(event) => {
              const files = event.target.files;
              if (files && !isEmpty(files)) {
                const file = files[0];
                formik.setFieldValue('file', file);
              }
            }}
          />
        </Button>
        {!!formik.values.file && (
          <span className={classes.fileName}>{formik.values.file?.name}</span>
        )}
      </Box>
    </Box>
  );
};
