import React from 'react';
import Box from '@material-ui/core/Box';
import Select from 'react-select';
import { ComboBoxBaseProps, OptionType } from './types';
import useStyles from './styles';

interface ComboBoxProps extends ComboBoxBaseProps {
  options: OptionType[];
}

const ComboBox: React.FC<ComboBoxProps> = ({
  options,
  name,
  id,
  setFieldValue,
  value,
  error,
  helperText,
  isMulti,
  isDisabled,
  placeholder,
  inputStyles = {},
  containerStyles = {}
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Select
        className={`${classes.field} ${error && classes.errorField}`}
        name={name}
        id={id}
        value={value}
        onChange={(option) => setFieldValue(name, option)}
        isSearchable={true}
        options={options}
        isMulti={isMulti}
        isDisabled={isDisabled}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 1301 }),
          menu: (provided) => ({ ...provided, zIndex: 1301 }),
          input: (provided) => ({ ...provided, ...inputStyles }),
          container: (provided) => ({ ...provided, ...containerStyles })
        }}
      />
      {error && helperText && (
        <Box className={`${classes.helperText} ${error && classes.errorText}`}>{helperText}</Box>
      )}
    </Box>
  );
};

export default ComboBox;
