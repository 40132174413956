import { UploadLinkEntry } from '../UploadViaLink/types';
import { UploadLinksReqType } from '../types';
import { MixpanelContext } from '../../../mixpanel/mixpanelContext';

export enum ActionTypes {
  UPDATE_SELECTED_COMPANY = 'UPDATE_SELECTED_COMPANY',
  ADD_DOCUMENT_LINKS = 'ADD_DOCUMENT_LINKS',
  REMOVE_DOCUMENT_LINK = 'REMOVE_DOCUMENT_LINK',
  UPDATE_DOCUMENT_LINK_ENTITY = 'UPDATE_DOCUMENT_LINK_ENTITY',
  RESET_LINKS = 'RESET_LINKS',
  TRIGGER_LOADING = 'TRIGGER_LINKS_LOADING',
  TRIGGER_ERROR = 'TRIGGER_ERROR',
  SUBMIT_UPLOAD_LINKS = 'SUBMIT_UPLOAD_LINKS'
}

export interface UpdateSelectedCompanyActionType {
  type: ActionTypes.UPDATE_SELECTED_COMPANY;
  payload: { ticker: string; cik: string; id: string };
}

export interface AddDocumentLinksActionType {
  type: ActionTypes.ADD_DOCUMENT_LINKS;
  payload: number; //  number of documents link rows to be added
}

export interface RemoveDocumentLinksActionType {
  type: ActionTypes.REMOVE_DOCUMENT_LINK;
  payload: number; // index of the row to be removed
}

export interface UpdateDocumentLinkActionType {
  type: ActionTypes.UPDATE_DOCUMENT_LINK_ENTITY;
  payload: {
    data: UploadLinkEntry;
    index: number;
  };
}

export interface ResetLinksFormActionType {
  type: ActionTypes.RESET_LINKS;
  payload: undefined;
}

export interface TriggerLoadingActionType {
  type: ActionTypes.TRIGGER_LOADING;
  payload: boolean;
}

export interface SubmitUploadLinksActionType {
  type: ActionTypes.SUBMIT_UPLOAD_LINKS;
  payload: { request: UploadLinksReqType; mixpanel: MixpanelContext };
}

export type ActionType =
  | UpdateSelectedCompanyActionType
  | AddDocumentLinksActionType
  | UpdateDocumentLinkActionType
  | TriggerLoadingActionType
  | ResetLinksFormActionType
  | SubmitUploadLinksActionType
  | RemoveDocumentLinksActionType;
