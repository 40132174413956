import { Axios } from 'core';
import { ConfigurationType } from './types';
import { SAVE_CONFIGURATIONS } from './endpoints';

export const createNewConfiguration = async (requestPayload: ConfigurationType) => {
  return await Axios.post<ConfigurationType>(SAVE_CONFIGURATIONS, requestPayload, {
    validateStatus: (status) => status < 500
  });
};

export const updateConfiguration = async (requestPayload: ConfigurationType) => {
  return await Axios.put<ConfigurationType>(SAVE_CONFIGURATIONS, requestPayload, {
    validateStatus: (status) => status < 500
  });
};
