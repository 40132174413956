import React, { Ref, useRef, useState } from 'react';
import { EntityGrid, notify } from 'store';

import { getPrivateDocumentIngestionColDef } from './constants';
import { errorMsgResolver } from '../Documents/utils';
import { exportPrivateDocumentIngestion, reRunDocumentIngest } from './services';
import { Button, CircularProgress } from '@material-ui/core';
import useClasses from './styles';
import { EntityGridRef } from 'store/src/EntityGrid/types';
import { buildFilterQueryParam } from 'store/src/EntityGrid/utils';

const PrivateDocumentIngestion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const gridRef: Ref<EntityGridRef> = useRef<EntityGridRef>(null);

  const classes = useClasses();

  const export_as_csv = async () => {
    setIsExporting(true);
    const gridState = gridRef?.current?.getState();
    if (gridState === undefined) {
      return;
    }
    let queryParam = '?';

    if (gridState?.filtrationConfig || gridState.columnFiltrationConfig) {
      const filters = buildFilterQueryParam(
        gridState.filtrationConfig,
        ['document__company__ticker'],
        [],
        gridState.columnFiltrationConfig?.items ?? []
      );
      queryParam += `&filters=${filters}`;
    }

    if (gridState.sortConfig) {
      queryParam += `&order=${gridState.sortConfig.sort === 'desc' ? '-' : ''}${
        gridState.sortConfig.field
      }`;
    }
    try {
      await exportPrivateDocumentIngestion(queryParam);
      notify({
        message: 'Exported Tickers Successfully',
        severity: 'success',
        open: true
      });
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setIsExporting(false);
    }
  };

  const reIngestDocument = async (id: number): Promise<void> => {
    try {
      setIsLoading(true);
      await reRunDocumentIngest(id);
      notify({
        message: 'Successfully ReIngested document',
        severity: 'success',
        open: true
      });
      setIsLoading(false);
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <EntityGrid
        actionContent={
          <Button
            className={classes.export}
            variant="contained"
            color="secondary"
            onClick={export_as_csv}
            disabled={isExporting}
          >
            {isExporting && <CircularProgress />}
            Export
          </Button>
        }
        entityName="private-document-ingestion"
        rows={[]}
        columns={getPrivateDocumentIngestionColDef(reIngestDocument)}
        loading={isLoading}
        searchableColumns={['document__company__ticker']}
        placeholder={'Search By Ticker'}
        isServerHandled={true}
        ref={gridRef}
      />
    </>
  );
};

export default PrivateDocumentIngestion;
