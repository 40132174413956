import React, { useState } from 'react';
import { AuthorizationGard, confirm, EntityGrid, notify } from 'store';
import { getDocumentColDef } from './constants';
import { Button } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import TranscriptIngestDialog from './TransciptIngestDialog';
import useAuthorization from '../../../hooks/useAuthorization';
import { PermissionKeys } from '../../../config/permission-keys';
import useCompaniesStyles from '../../Companies/CompaniesActions/styles';
import { reRunTranscriptIngest } from '../services';
import { errorMsgResolver } from '../utils';

const TranscriptIngest: React.FC = () => {
  const [isTranscriptIngestionOpen, setIsTranscriptIngestionOpen] = useState<boolean>(false);
  const canUploadDocument = useAuthorization(PermissionKeys.CAN_UPLOAD_DOCUMENTS);
  const companiesClasses = useCompaniesStyles();

  const reRun = async (transcriptIngestID: number): Promise<void> => {
    try {
      const response = await reRunTranscriptIngest(transcriptIngestID);
      if (response) {
        notify({
          message: 'ReRun Successfully',
          severity: 'success',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    }
  };

  return (
    <>
      <EntityGrid
        actionContent={
          <AuthorizationGard isAuthorized={canUploadDocument}>
            <Button
              className={companiesClasses.root}
              variant="contained"
              color="primary"
              startIcon={<AddOutlinedIcon />}
              onClick={() => setIsTranscriptIngestionOpen(true)}
              disabled={!canUploadDocument}
            >
              Add Transcript
            </Button>
          </AuthorizationGard>
        }
        entityName="transcripts"
        rows={[]}
        columns={getDocumentColDef(reRun)}
        searchableColumns={['id']}
        placeholder="Search by ID..."
      />
      <TranscriptIngestDialog
        isOpen={isTranscriptIngestionOpen}
        handleClose={() => setIsTranscriptIngestionOpen(false)}
      />
    </>
  );
};

export default TranscriptIngest;
