import Box from '@material-ui/core/Box';
import { ComboBox, OptionType } from 'ui-kit';
import { CIK, EMPTY_IDENTIFIER_VALUE, identifierTypesOptions, ISIN } from '../constants';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useMemo } from 'react';
import { CompanyType, FormikType } from 'store';
import useStyles from './style';
import { FieldArray, FormikProvider, getIn } from 'formik';
import { OptionByValue } from '../types';
import { cloneDeep } from 'lodash';
import { Button, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import clsx from 'clsx';
import FormHelperText from '@material-ui/core/FormHelperText';

const MANDATORY_FIELDS = [CIK, ISIN];

interface CompanyIdentifierFormProps {
  formik: FormikType<CompanyType>;
  isEdit: boolean;
}
export const CompanyIdentifiersForm = ({ formik, isEdit }: CompanyIdentifierFormProps) => {
  const classes = useStyles();

  const identifierTypeOptionsByValue: OptionByValue = useMemo(() => {
    return identifierTypesOptions.reduce((collector: OptionByValue, option) => {
      collector[option.value] = option;
      return collector;
    }, {} as OptionByValue);
  }, [formik.values.identifiers]);

  const isIdentifierRequired = typeof formik.errors.identifiers === 'string';
  const isPrivate = formik.values.is_private;
  const mandatoryFields = isPrivate ? [] : MANDATORY_FIELDS;

  useEffect(() => {
    if (isPrivate) {
      const newIdentifiers = formik.values.identifiers.filter(
        (identifier) => identifier.id !== EMPTY_IDENTIFIER_VALUE
      );
      formik.setFieldValue('identifiers', newIdentifiers);
    }
  }, [isPrivate]);

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={'identifiers'}
        validateOnChange={true}
        render={({ remove, push }) => (
          <>
            {formik.values.identifiers.map((identifier, index) => (
              <>
                <Box className={classes.comboBoxInput}>
                  <ComboBox
                    name={`identifiers[${index}].identifier_type`}
                    id={`identifiers[${index}].identifier_type`}
                    placeholder="Select identifier Types"
                    value={identifierTypeOptionsByValue[identifier.identifier_type]}
                    setFieldValue={(field, value) => {
                      const newIdentifiers = cloneDeep(formik.values.identifiers);
                      newIdentifiers[index].identifier_type = (value as OptionType).value;
                      formik.setFieldValue('identifiers', newIdentifiers);
                    }}
                    options={identifierTypesOptions}
                    error={Boolean(getIn(formik.errors, `identifiers[${index}].identifier_type`))}
                    helperText={
                      Boolean(getIn(formik.touched, `identifiers[${index}].identifier_type`))
                        ? getIn(formik.errors, `identifiers[${index}].identifier_type`) &&
                          'identifier type is required'
                        : undefined
                    }
                  />
                </Box>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    !mandatoryFields.includes(identifier.identifier_type)
                      ? classes.deleteIdentifierContainer
                      : undefined
                  )}
                >
                  <TextField
                    label="Identifier Value"
                    placeholder="Enter Identifier Value"
                    className={classes.field}
                    id={`identifiers[${index}].identifier_value`}
                    name={`identifiers[${index}].identifier_value`}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={identifier.identifier_value}
                    onChange={(event) => {
                      const newIdentifiers = cloneDeep(formik.values.identifiers);
                      newIdentifiers[index].identifier_value = event.target.value?.replace(
                        /^0+/,
                        ''
                      );
                      formik.setFieldValue('identifiers', newIdentifiers);
                    }}
                    variant="outlined"
                    error={Boolean(getIn(formik.errors, `identifiers[${index}].identifier_value`))}
                    helperText={
                      Boolean(getIn(formik.touched, `identifiers[${index}].identifier_value`))
                        ? getIn(formik.errors, `identifiers[${index}].identifier_value`) &&
                          'identifier value is required'
                        : undefined
                    }
                  />
                  {!mandatoryFields.includes(identifier.identifier_type) && (
                    <IconButton onClick={() => remove(index)}>
                      <Remove color={'error'} />
                    </IconButton>
                  )}
                </Box>
              </>
            ))}

            {isIdentifierRequired && (
              <>
                <FormHelperText error={true} className={classes.identifierErrorText}>
                  {formik.errors.identifiers}
                </FormHelperText>
                <Box />
              </>
            )}

            <Button
              variant={'text'}
              color={'primary'}
              onClick={() => push({})}
              className={classes.addIdentifier}
            >
              <Add />
              Add Identifier
            </Button>
          </>
        )}
      />
    </FormikProvider>
  );
};
