import { createSelector } from 'reselect';
import { HomeStateType } from 'components/Home/types';
import { RootStateType } from 'store/root-reducer';

export const selectUser = createSelector(
  (state: RootStateType) => state.global,
  (homeState: HomeStateType) => homeState.user
);

export const selectIsLoading = createSelector(
  (state: RootStateType) => state.global,
  (homeState: HomeStateType) => homeState.isLoading
);
