import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  spinner: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.text.secondary,
    zIndex: 10
  },
  editDocumentForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr'
    }
  }
}));
