import React, { useRef, useState } from 'react';
import { EntityGridRef } from 'store/src/EntityGrid/types';
import { PreqinIngestionModal } from './PreqinIngestionModal';
import { AuthorizationGard, confirm, EntityGrid, notify } from 'store';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import useAuthorization from '../../hooks/useAuthorization';
import { PermissionKeys } from '../../config/permission-keys';
import useStyles from '../Companies/CompaniesActions/styles';
import { getPreqinIngestionColDef } from './constants';
import { reingestPreqinDocuments } from './services';
import { errorMsgResolver } from '../Documents/utils';

const PreqinIngestion = () => {
  const entityRef = useRef<EntityGridRef>(null);
  const [isIngestModalOpen, setIsIngestModalOpen] = useState(false);
  const canUploadDocuments = useAuthorization(PermissionKeys.CAN_ADD_PRIVATE_INGESTION);
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  const onRerunIngestion = async (failedOnly = false, ingestionJobId: number) => {
    setLoading(true);
    try {
      const responses = await reingestPreqinDocuments({
        ingestionJobId,
        failedOnly
      });
      if (responses.status !== 200) {
        notify({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          message: errorMsgResolver((responses.data as any).details),
          severity: 'error',
          open: true
        });
      } else {
        notify({
          message: 'Ingestion is in progress',
          severity: 'success',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setLoading(false);
    }
  };

  const onRerunAll = (ingestionJobId: number) => {
    confirm(
      'Rerun All',
      'Are you sure you want to rerun ingestion process for all documents in this job ?',
      () => void onRerunIngestion(false, ingestionJobId)
    );
  };
  const onRerunFailedOnly = (ingestionJobId: number) => {
    confirm(
      'Rerun Failed Only',
      'Are you sure you want to rerun ingestion process for failed documents in this job ?',
      () => void onRerunIngestion(true, ingestionJobId)
    );
  };

  const actions = (
    <Box display="flex">
      <AuthorizationGard isAuthorized={canUploadDocuments}>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          startIcon={<AddBoxOutlined />}
          onClick={() => setIsIngestModalOpen(true)}
          disabled={!canUploadDocuments}
        >
          Documents
        </Button>
      </AuthorizationGard>
      <Box m={1} />
    </Box>
  );

  return (
    <>
      <PreqinIngestionModal
        isOpen={isIngestModalOpen}
        onClose={() => setIsIngestModalOpen(false)}
      />
      <EntityGrid
        loading={isLoading}
        ref={entityRef}
        actionContent={actions}
        entityName="preqin"
        columns={getPreqinIngestionColDef(onRerunAll, onRerunFailedOnly, canUploadDocuments)}
        rows={[]}
        searchableColumns={['registry_name']}
        placeholder="Search by registry name"
      />
    </>
  );
};

export default PreqinIngestion;
