import React, { useContext } from 'react';
import { GridHeaderProps } from '../types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Title from '../Title';
import Actions from '../Actions';
import Search from '../Search';
import StateContext from '../state/StateContext';
import { triggerFilter } from '../state/actionCreator';

const Header: React.FC<GridHeaderProps> = ({
  placeholder,
  actionContent,
  entityName,
  entitySubTitle,
  onRefresh,
  showRefreshIcon = false
}) => {
  const { dispatch } = useContext(StateContext);

  const onSearchChange = (searchText: string) => {
    dispatch(
      triggerFilter({
        value: searchText
      })
    );
  };

  return (
    <Box
      marginBottom={3}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Title title={entityName} subTitle={entitySubTitle} />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {showRefreshIcon && (
          <Box mr={3}>
            <IconButton onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Box>
        )}
        <Actions actionContent={actionContent} />
        <Search onChange={onSearchChange} placeholder={placeholder} />
      </Box>
    </Box>
  );
};

export default Header;
