import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { StyledDialogActions } from './styles';
import EventBus, { closeBus } from '../EventBus';

export default function (title: string, message: string, onConfirm: () => void) {
  const key = Date.now();

  EventBus &&
    EventBus.notice({
      closable: true,
      key: key,
      content: (
        <Dialog open={true} maxWidth="sm" fullWidth onClose={() => closeBus(key)}>
          <DialogTitle>{title}</DialogTitle>
          <Box position="absolute" top={0} right={0}>
            <IconButton>
              <Close onClick={() => closeBus(key)} />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography>{message}</Typography>
          </DialogContent>
          <StyledDialogActions>
            <Button color="primary" variant="outlined" onClick={() => closeBus(key)}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onConfirm();
                closeBus(key);
              }}
            >
              Confirm
            </Button>
          </StyledDialogActions>
        </Dialog>
      )
    });
}
