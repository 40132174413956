import { Axios } from 'core';
import { EXPORT_PRIORITIES, MODIFY_PRIORITISE, UPDATE_PRIORITIES_CSV } from './endpoints';

export const modifyTickerPrioritise = async (
  requestPayload: { ticker: string; priority: string }[]
) => {
  return await Axios.put<any>(MODIFY_PRIORITISE, requestPayload, {
    validateStatus: (status) => status < 500
  });
};

export const exportTickerPrioritise = async (priority: string, filename: string) => {
  const url = `${EXPORT_PRIORITIES}?priority=${priority}`;
  const response = await Axios.get<string>(url);
  const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const updatePrioritiesCSV = async (file: File): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await Axios.post(UPDATE_PRIORITIES_CSV, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.status === 200;
};
