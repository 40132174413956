import { ComboBox, Dialog, OptionType } from 'ui-kit';
import { makeStyles, styled } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import React, { useState, Fragment, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserTier, UserType, User } from '../types';
import {
  PRODUCT_TYPE_OPTIONS,
  USER_STATUS_OPTIONS,
  USER_TIER_OPTIONS,
  USER_TYPE_OPTIONS
} from '../constants';
import { createPreAccount, updateUser } from '../services';
import { FormikType, TickerDropdown, notify } from 'store';
import { errorMsgResolver } from '../../Documents/utils';
import Button from '@material-ui/core/Button';
import { ComboBoxBaseProps } from 'ui-kit/src/components/ComboBox/types';

interface IUserModalProps {
  user?: User | undefined;
  isOpen: boolean;
  handleClose: (arg0: boolean) => void;
  mode?: 'CREATE' | 'EDIT';
}

const Container = styled('div')({
  display: 'flex',
  gap: '12px',
  flex: '1',
  flexDirection: 'column',
  justifyContent: 'space-around',
  margin: 'auto',
  padding: '0 8px',

  '& > div': {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',

    '&.createActionsWrapper': {
      gap: '8px',
      flexDirection: 'row',
      margin: '0 auto'
    }
  },

  '& input': {
    padding: '12px 8px'
  }
});

const useStyles = makeStyles({
  inputBox: {
    '& div': {
      borderRadius: '4px'
    }
  }
});

const CreateUserFormType = Yup.object().shape({
  email: Yup.string().email().required(),
  tier: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('Tier is required'),
  userType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  })
});

const EditUserFormType = Yup.object().shape({
  email: Yup.string().email().required(),
  tier: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('Tier is required'),
  userType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  }),
  marketplaceStatus: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  }),
  dailyDownloadLimit: Yup.string().required(),
  totalDownloadLimit: Yup.string().required()
});

export interface IUserType {
  id?: number;
  email?: string;
  tier?: OptionType | undefined;
  userType: OptionType | undefined;
  is_active?: boolean;
  ticker?: OptionType[];
  type: OptionType | undefined;
  marketplaceStatus?: OptionType | undefined;
  dailyDownloadLimit?: number;
  totalDownloadLimit?: number;
}

const handleCreatePreAccount = async ({ email, tier, userType, ticker, type }: IUserType) => {
  const { hashCode, error } = await createPreAccount(
    email as string,
    tier?.value as string,
    ticker?.map((o) => o.value).join(',') as string,
    userType?.value as string,
    type?.value as string
  );

  if (error) {
    notify({
      message: error,
      severity: 'error',
      open: true
    });
  } else {
    notify({
      message: 'user created successfully',
      severity: 'success',
      open: true
    });
  }

  return hashCode;
};

const handleUpdateUser = async ({
  id,
  tier,
  userType,
  is_active,
  type,
  marketplaceStatus,
  dailyDownloadLimit,
  totalDownloadLimit
}: IUserType) => {
  const { success, error }: { success: boolean; error: string } = await updateUser(
    (id as unknown) as string,
    tier?.value as UserTier,
    userType?.value as UserType,
    is_active,
    type?.value,
    marketplaceStatus?.value,
    dailyDownloadLimit,
    totalDownloadLimit
  );

  if (success) {
    notify({
      message: 'user updated successfully',
      severity: 'success',
      open: true
    });
  } else {
    notify({
      message: error,
      severity: 'error',
      open: true
    });
  }
};

export const UserModal = ({ handleClose, isOpen, user, mode = 'CREATE' }: IUserModalProps) => {
  const textRef = useRef<HTMLInputElement>(null);

  const classes = useStyles();

  const [isEditingUser, setIsEditingUser] = useState(false);
  const [linkGenerated, setLinkGenerated] = useState<string | undefined>(undefined);

  const formik = useFormik<IUserType>({
    initialValues: (mode === 'EDIT'
      ? {
          id: user?.id,
          email: user?.email,
          tier: USER_TIER_OPTIONS.find((opt) => opt.value === user?.tier) as OptionType,
          userType: USER_TYPE_OPTIONS.find((opt) => opt.value === user?.user_type) as OptionType,
          is_active: user?.is_active,
          marketplaceStatus: USER_STATUS_OPTIONS.find(
            (opt) => opt.value === user?.marketplace_status
          ) as OptionType,
          dailyDownloadLimit: user?.daily_download_limit,
          totalDownloadLimit: user?.total_download_limit,
          type: PRODUCT_TYPE_OPTIONS.find((opt) => opt.value === user?.product_type) as OptionType
        }
      : {
          email: '',
          tier: undefined,
          type: undefined,
          userType: undefined,
          isTrialTier: true,
          ticker: []
        }) as IUserType,
    isInitialValid: false,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: mode === 'EDIT' ? EditUserFormType : CreateUserFormType,

    validate: ({ tier, userType, ticker, type }: IUserType) => {
      if (!tier) {
        return { tier: 'Select a tier' };
      }
      if (mode === 'CREATE' && tier.value === 'trial' && !ticker?.length) {
        return { ticker: 'Select a ticker' };
      }
      if (
        (tier.value === 'enterprise' || (mode === 'CREATE' && tier?.value !== 'trial')) &&
        !userType?.value
      ) {
        return { userType: 'Select a user type' };
      }
      if (type === undefined) {
        return { type: 'Select a product type' };
      }
    },
    onSubmit: async ({
      id,
      tier,
      userType,
      is_active,
      email,
      ticker,
      type,
      dailyDownloadLimit,
      totalDownloadLimit,
      marketplaceStatus
    }) => {
      try {
        setIsEditingUser(true);

        if (mode === 'CREATE') {
          await handleCreatePreAccount({
            email,
            tier,
            ticker,
            userType,
            type
          }).then((hashCode) => {
            if (hashCode) {
              setLinkGenerated(
                `https://marketplace.daloopa.com/sign-up?email=${
                  email as string
                }&hashcode=${hashCode}`
              );
              // closeAndReset(true);
            }
          });
        } else {
          await handleUpdateUser({
            id,
            tier,
            userType,
            is_active,
            type,
            marketplaceStatus,
            dailyDownloadLimit,
            totalDownloadLimit
          }).then(() => {
            closeAndReset(true);
          });
        }
      } catch (error) {
        notify({
          message: errorMsgResolver(error as string),
          severity: 'error',
          open: true
        });
      }
      setIsEditingUser(false);
    }
  });

  const isCreateMode = mode === 'CREATE';
  const isTrialTier = formik.values.tier?.value === 'trial';

  const closeAndReset = (refresh = false) => {
    formik.resetForm();
    setLinkGenerated(undefined);
    handleClose(refresh);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(linkGenerated as string);
    textRef.current?.focus();
    notify({
      message: 'copied to clipboard',
      severity: 'success',
      open: true
    });
  };

  const handleSetValue = (field: string, value: ComboBoxBaseProps['value']) => {
    void formik.setFieldValue(field, value);
    void formik.setFieldTouched(field, true);
  };

  const productTypeSelector = (
    <div>
      <label htmlFor="type">Product Type</label>
      <ComboBox
        name="type"
        placeholder="Select Product Type"
        value={formik.values.type}
        setFieldValue={handleSetValue}
        options={PRODUCT_TYPE_OPTIONS}
        error={!!formik.errors.type}
        helperText={formik.errors.type}
      />
    </div>
  );

  const userTypeSelector = !isTrialTier && (
    <div>
      <label htmlFor="userType">User Type</label>
      <ComboBox
        name="userType"
        placeholder="Select userType"
        value={formik.values.userType}
        setFieldValue={handleSetValue}
        options={USER_TYPE_OPTIONS}
        error={!!formik.errors.userType}
        helperText={formik.errors.userType}
      />
    </div>
  );

  const tierSelector = (
    <div>
      <label htmlFor="tier">Tier</label>
      <ComboBox
        name="tier"
        placeholder="Select Tier"
        value={formik.values.tier}
        setFieldValue={handleSetValue}
        options={USER_TIER_OPTIONS}
        error={!!formik.errors.tier}
        helperText={formik.errors.tier}
      />
    </div>
  );

  return (
    <Dialog
      isOpen={isOpen}
      title={isCreateMode ? 'Create User Pre-account' : 'Update User'}
      handleClose={() => closeAndReset(false)}
      width={'sm'}
      hideDialogActions={isCreateMode}
      handleOk={formik.handleSubmit}
      isSaveDisabled={!formik.isValid}
      minHeight={'35vh'}
      isLoading={isEditingUser}
    >
      <Container>
        {isCreateMode && (
          <>
            <div>
              <label htmlFor="email">Email Address</label>
              <TextField
                type="email"
                name="email"
                placeholder="Email Address"
                InputLabelProps={{
                  shrink: true
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                className={classes.inputBox}
              />
            </div>
            {tierSelector}
            {productTypeSelector}
            {isTrialTier && (
              <div>
                <label htmlFor="ticker">Ticker</label>
                <TickerDropdown
                  formik={(formik as unknown) as FormikType}
                  selectedValue={formik.values.ticker}
                  isMulti
                  withLiveModels
                />
                <FormHelperText error={!!formik.errors.ticker?.length}>
                  {formik.errors.ticker}
                </FormHelperText>
              </div>
            )}
            {userTypeSelector}
            <div className="createActionsWrapper">
              <Button
                color="primary"
                disabled={!formik.isValid || !!linkGenerated}
                onClick={() => formik.handleSubmit()}
              >
                Create Signup link
              </Button>

              <Button color="primary" disabled={!linkGenerated} onClick={handleCopyToClipboard}>
                Copy Signup link
              </Button>
              <Button
                color="primary"
                disabled={!formik.dirty}
                onClick={() => {
                  formik.resetForm();
                  setLinkGenerated(undefined);
                }}
              >
                Reset form
              </Button>
            </div>
          </>
        )}

        {!isCreateMode && (
          <>
            <div>
              <label htmlFor="email">Email Address</label>
              {formik.values.email}
            </div>
            {productTypeSelector}
            <div>
              <label htmlFor="dailyDownloadLimit">Daily Limit</label>
              <TextField
                type={'number'}
                name="dailyDownloadLimit"
                placeholder="Daily Limit"
                InputLabelProps={{
                  shrink: true
                }}
                value={formik.values.dailyDownloadLimit}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.dailyDownloadLimit}
                helperText={formik.errors.dailyDownloadLimit}
                inputProps={{ type: 'number', min: 0 }}
                className={classes.inputBox}
              />
            </div>
            <div>
              <label htmlFor="totalDownloadLimit">Total Limit</label>
              <TextField
                type={'number'}
                name="totalDownloadLimit"
                placeholder="Total Limit"
                InputLabelProps={{
                  shrink: true
                }}
                value={formik.values.totalDownloadLimit}
                onChange={formik.handleChange}
                variant="outlined"
                error={!!formik.errors.totalDownloadLimit}
                helperText={formik.errors.totalDownloadLimit}
                inputProps={{ type: 'number', min: 0 }}
                className={classes.inputBox}
              />
            </div>
            <div>
              <label htmlFor="markeplaceStatus">User Status</label>
              <ComboBox
                name="marketplaceStatus"
                placeholder="Select User Status"
                value={formik.values.marketplaceStatus}
                setFieldValue={handleSetValue}
                options={USER_STATUS_OPTIONS}
                error={!!formik.errors.marketplaceStatus}
                helperText={formik.errors.marketplaceStatus}
              />
            </div>
            {tierSelector}
            {userTypeSelector}
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.is_active}
                  onChange={() => {
                    formik.setFieldValue('is_active', !formik.values.is_active);
                    formik.setFieldTouched('is_active', true);
                  }}
                  color="primary"
                  name="active"
                />
              }
              label="Active"
            />
          </>
        )}
        {linkGenerated && (
          <TextField inputRef={textRef} variant="outlined" multiline value={linkGenerated} />
        )}
      </Container>
    </Dialog>
  );
};
