import React, { useMemo } from 'react';

import AppLogoImg from '../../public/logo.png';

import './AppLogo.scss';
import { getAppLogoSize } from './utils';

interface AppLogoProps {
  title: string;
  flow?: 'row' | 'column';
  size?: 'sm' | 'lg' | 'md';
}

const AppLogo: React.FC<AppLogoProps> = ({ title, flow = 'column', size = 'md' }) => {
  const logoSize = useMemo(() => getAppLogoSize(size), [size]);

  return (
    <div className={`app-logo-container ${flow}-logo`}>
      <div className="app-logo-avatar" style={{ width: logoSize, height: logoSize }}>
        <img src={AppLogoImg} alt="" />
      </div>
      <div className="app-logo-title">{title}</div>
    </div>
  );
};

export default AppLogo;
