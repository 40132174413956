import { gql, GraphQL, ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from 'core';
import Cookies from 'js-cookie';
import { LoginPayloadType, LoginResponseType } from './types';

export const login: (
  data: LoginPayloadType,
  onSuccess: () => void,
  onError: (e: string) => void
) => void = async ({ email, password }, onSuccess, onError) => {
  try {
    const { data } = await GraphQL.mutate<LoginResponseType>({
      mutation: gql`
        mutation tokenAuthMutation($email: String!, $password: String!) {
          tokenAuth(email: $email, password: $password) {
            token
            refreshToken
          }
        }
      `,
      variables: {
        email,
        password
      },
      fetchPolicy: 'no-cache'
    });
    if (data?.tokenAuth && data?.tokenAuth?.refreshToken) {
      const accessToken = data?.tokenAuth.token;
      const refreshToken = data?.tokenAuth.refreshToken;
      Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken);
      Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken);
      onSuccess();
    }
  } catch (e) {
    onError(e.message);
  }
};
