import React from 'react';
import { SSORedirect } from 'core';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export interface AuthenticatedRouteProps extends RouteProps {
  loginRoute?: string;
}

export const AuthenticatedRoute = ({ children, loginRoute, ...rest }: AuthenticatedRouteProps) => (
  <Route
    {...rest}
    render={({ location }) => {
      return isLoggedIn().isAuthenticated ? (
        children
      ) : USE_SSO_LOGIN ? (
        <SSORedirect />
      ) : (
        <Redirect to={{ pathname: loginRoute, state: { from: location } }} exact={true} />
      );
    }}
  />
);
