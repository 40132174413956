import React, { Ref, useEffect, useRef, useState } from 'react';
import { CompanyType, confirm, EntityGrid, notify } from 'store';
import { GridRowData } from '@material-ui/data-grid';

import CompaniesActions from './CompaniesActions';
import { getCompaniesColDef, TICKER_PRIORITY_MAP } from './constants';
import { useAction } from './hooks/useAction';
import useAuthorization from 'hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';
import CompanyForm from './CompanyForm';
import { EntityGridRef } from 'store/src/EntityGrid/types';
import { useSelector } from 'react-redux';
import { Selectors } from './state';
import ModifyTickersPriority from './ModifyTickersPriority';
import { errorMsgResolver } from '../Documents/utils';
import { exportTickerPrioritise } from './ModifyTickersPriority/services';
import ModifyTickersPriorityCSVInput from './ModifyTickersPriority/ModifyTickerPriorityCSVInput';

const Companies: React.FC = () => {
  const actions = useAction();

  const canDelete = useAuthorization(PermissionKeys.CAN_DELETE_COMPANIES);
  const canEdit = useAuthorization(PermissionKeys.CAN_EDIT_COMPANIES);
  const gridRef: Ref<EntityGridRef> = useRef<EntityGridRef>(null);
  const isAddCompanySuccess = useSelector(Selectors.selectIsAddCompanySuccess);
  const isEditCompanySuccess = useSelector(Selectors.selectIsEditCompanySuccess);
  const [tickerPriorityFilter, setTickerPriorityFilter] = useState<string | undefined>(undefined);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    actions.loadIndustriesAction();
  }, []);

  useEffect(() => {
    gridRef?.current?.refresh();
  }, [tickerPriorityFilter]);

  useEffect(() => {
    if (isEditCompanySuccess) {
      gridRef.current?.refresh();
      actions.setRequestStatus({ isEditSuccess: false });
    }
    if (isAddCompanySuccess) {
      gridRef.current?.refresh();
      actions.setRequestStatus({ isAddSuccess: false });
    }
  }, [isEditCompanySuccess, isAddCompanySuccess]);

  const onDeleteRow = (row: GridRowData) => {
    confirm('Delete Confirmation', 'Are you sure you want to delete this company ?', () => {
      actions.deleteCompanyAction({ companyIds: [row.id] });
    });
  };

  const onEditRow = (row: GridRowData) => {
    actions.openEditCompanyAction(row as CompanyType);
  };

  const triggerExportPriorities = async () => {
    if (tickerPriorityFilter === undefined) {
      return;
    }
    setIsExporting(true);
    try {
      await exportTickerPrioritise(
        tickerPriorityFilter,
        `${TICKER_PRIORITY_MAP[tickerPriorityFilter]}_tickers`
      );
      notify({
        message: 'Exported Tickers Successfully',
        severity: 'success',
        open: true
      });
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <EntityGrid
        entityName="company"
        actionContent={
          <CompaniesActions
            onTickerPriorityChange={setTickerPriorityFilter}
            triggerAddCompany={actions.openAddCompanyAction}
            triggerChangePriorities={() => actions.triggerModifyTickerPriorityAction(true)}
            triggerExportPriorities={triggerExportPriorities}
            isExporting={isExporting}
            isExportingDisabled={tickerPriorityFilter === undefined}
            triggerUpdatePrioritiesUsingCSV={() =>
              actions.triggerModifyTickerPriorityCSVAction(true)
            }
          />
        }
        extraFilters={[{ priority: tickerPriorityFilter }]}
        rows={[]}
        columns={getCompaniesColDef(onEditRow, onDeleteRow, canDelete, canEdit)}
        searchableColumns={['ticker']}
        placeholder="Search by Ticker..."
        ref={gridRef}
      />
      <CompanyForm />
      <ModifyTickersPriority />
      <ModifyTickersPriorityCSVInput />
    </>
  );
};

export default Companies;
