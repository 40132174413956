import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  modifyPriorities: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      boxShadow: 'none'
    }
  },
  modifyPrioritiesUsingCSV: {
    marginLeft: 12
  },
  exportPriorities: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      boxShadow: 'none'
    },
    marginLeft: 12
  }
}));
