import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const styles = (theme: Theme) => ({
  success: {
    color: theme.palette.success.main
  },
  failure: {
    color: theme.palette.error.main
  },
  pending: {
    color: theme.palette.warning.main
  }
});

interface StatusBadgeProps {
  status: 'success' | 'failure' | 'pending';
  classes: Record<string, string>;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, classes }) => {
  const getIcon = () => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={classes.success} />;
      case 'failure':
        return <ErrorIcon className={classes.failure} />;
      case 'pending':
        return <HourglassEmptyIcon className={classes.pending} />;
      default:
        return null;
    }
  };

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent={getIcon()}
    />
  );
};

export default withStyles(styles)(StatusBadge);
