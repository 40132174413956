import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from 'components/Companies/state';
import { Dialog } from 'ui-kit';
import { useAction } from '../hooks/useAction';
import { notify } from 'store';
import { Box, Button } from '@material-ui/core';
import { errorMsgResolver } from '../../Documents/utils';
import { updatePrioritiesCSV } from './services';
import { isEmpty } from 'lodash';

const ModifyTickersPriorityCSVInput = () => {
  const { triggerModifyTickerPriorityCSVAction } = useAction();
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const isOpen = useSelector(Selectors.selectIsUpdatePrioritiesCSVModalOpen);

  const updateUsingCSVFile = async () => {
    setLoading(true);
    if (!file) {
      return;
    }
    try {
      const isSuccess = await updatePrioritiesCSV(file);
      if (isSuccess) {
        notify({
          message: 'Successfully updated priorities',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message: 'Failed to updated priorities',
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setLoading(false);
      triggerModifyTickerPriorityCSVAction(false);
      setFile(undefined);
    }
  };

  return (
    <Dialog
      width="sm"
      isOpen={isOpen}
      title="Modify Tickers Priorities Using CSV File"
      handleClose={() => triggerModifyTickerPriorityCSVAction(false)}
      handleOk={updateUsingCSVFile}
      isLoading={isLoading}
    >
      <Box>
        <Button variant="contained" component="label" color={'primary'}>
          Choose CSV file
          <input
            type="file"
            accept="text/csv"
            hidden
            onChange={(event) => {
              const files = event.target.files;
              if (files && !isEmpty(files)) {
                const file = files[0];
                setFile(file);
              }
            }}
          />
        </Button>
        {!!file && <div>{file?.name}</div>}
      </Box>
    </Dialog>
  );
};

export default ModifyTickersPriorityCSVInput;
