import React from 'react';
import { SidebarItem } from 'ui-kit';
import { HomeRoutes } from './routes';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DescriptionIcon from '@material-ui/icons/Description';
import WorkIcon from '@material-ui/icons/Work';
import VpnKey from '@material-ui/icons/VpnKey';
import SettingsIcon from '@material-ui/icons/Settings';
import DocumentScannerIcon from '@material-ui/icons/PostAdd';
import Person from '@material-ui/icons/Person';
import { AmpStories, House, RecordVoiceOver } from '@material-ui/icons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

export const Sidebar_Items: SidebarItem[] = [
  {
    to: HomeRoutes.Companies,
    title: 'Companies',
    icon: <LocationCityIcon />
  },
  {
    to: HomeRoutes.Documents,
    title: 'Documents',
    icon: <DescriptionIcon />
  },
  {
    to: HomeRoutes.PrivateDocumentIngestion,
    title: 'Document Ingestion',
    icon: <PictureAsPdfIcon />
  },
  {
    to: HomeRoutes.BatchLogs,
    title: 'Crawler Statuses',
    icon: <WorkIcon />
  },
  // {
  //   to: HomeRoutes.CompSheets,
  //   title: 'Comp Sheets',
  //   icon: <PlaylistAddIcon />
  // },
  {
    to: HomeRoutes.ApiKeys,
    title: 'Api Keys',
    icon: <VpnKey />
  },
  {
    to: HomeRoutes.Users,
    title: 'Users',
    icon: <Person />
  },
  {
    to: HomeRoutes.SnapshotModels,
    title: 'Snapshot Models',
    icon: <AmpStories />
  },
  {
    to: HomeRoutes.IndustryModels,
    title: 'Industry Models',
    icon: <House />
  },
  {
    to: HomeRoutes.Configurations,
    title: 'Configurations',
    icon: <SettingsIcon />
  },
  {
    to: HomeRoutes.Preqin,
    title: 'Preqin',
    icon: <DocumentScannerIcon />
  },
  {
    to: HomeRoutes.Transcripts,
    title: 'Transcripts',
    icon: <RecordVoiceOver />
  }
];
