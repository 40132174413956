import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { selectDocumentLinks } from '../state/selectors';
import DocumentLinkRow from './DocumentLinkRow';

const DocumentLinks: React.FC = () => {
  const classes = useStyles();
  const rows = useSelector(selectDocumentLinks);

  const rowsEl = useMemo(
    () => rows?.map((row, index) => <DocumentLinkRow key={row.id} index={index} />),
    [rows.length]
  );

  const emptyMsg = <Box className={classes.emptyMsg}>Add Links to be uploaded</Box>;

  return <Box className={classes.linksContainer}>{rows?.length === 0 ? emptyMsg : rowsEl}</Box>;
};

export default DocumentLinks;
