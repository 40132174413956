import React, { useMemo, useState } from 'react';
import useStyles from './styles';
import { Dialog } from 'ui-kit';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { ingestPreqinDocuments } from './services';
import { errorMsgResolver } from '../Documents/utils';
import { notify } from 'store';

interface PreqinIngestionModal {
  isOpen: boolean;
  onClose: () => void;
}

export const PreqinIngestionModal = ({ isOpen, onClose }: PreqinIngestionModal) => {
  const [projectName, setProjectName] = useState<string>('');
  const [registryName, setRegistryName] = useState<string>('');
  const [preqinId, setPreqinId] = useState<string>('');
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  const isSaveDisabled = useMemo(() => {
    return isEmpty(projectName) || isEmpty(registryName);
  }, [projectName, registryName]);

  const onSave = async () => {
    setLoading(true);
    try {
      const responses = await ingestPreqinDocuments({
        project_name: projectName,
        registry_name: registryName,
        preqin_id: preqinId
      });
      if (responses.status !== 200) {
        notify({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          message: errorMsgResolver((responses.data as any).details),
          severity: 'error',
          open: true
        });
      } else {
        notify({
          message: 'Ingestion is in progress',
          severity: 'success',
          open: true
        });
        onClose();
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title="Preqin"
      handleClose={onClose}
      minHeight="200px"
      isSaveDisabled={isSaveDisabled}
      handleOk={onSave}
      isLoading={isLoading}
    >
      <Box className={classes.container}>
        <TextField
          label="Project Name"
          fullWidth={true}
          placeholder="2023_Financials_Data"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value?.trim())}
          variant="outlined"
          required={true}
        />
        <TextField
          label="Registry Name"
          fullWidth={true}
          placeholder="Week_44_2023"
          value={registryName}
          onChange={(e) => setRegistryName(e.target.value?.trim())}
          variant="outlined"
          required={true}
        />
        <TextField
          label="Preqin ID"
          fullWidth={true}
          placeholder="Preqin ID"
          value={preqinId}
          onChange={(e) => setPreqinId(e.target.value?.trim())}
          variant="outlined"
          required={false}
        />
      </Box>
    </Dialog>
  );
};
