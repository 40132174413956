import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { TickerDropdown } from 'store';
import { DataTable, OptionType } from 'ui-kit';
import UploadFilesIcon from 'icons/uploadFiles.png';

import { getFilesCols, onRemoveFileType } from './types';
import { FileType } from './types';
import useStyles from './styles';
import { removeFileFromSelectedFiles, updateFileLanguage, updateFilePeriod } from '../utils';
import Select from 'react-select';

interface UploadFilesFormProps {
  error: string;
  selectedTicker: OptionType | OptionType[] | undefined;
  setSelectedTicker: (ticker: OptionType | OptionType[]) => void;
  handleAddFiles: () => void;
  activeFiles: FileType[];
  setActiveFiles: (files: FileType[]) => void;
  documentTypes: string[];
  selectedDocumentType: OptionType | undefined;
  setSelectedDocumentType: (option: OptionType) => void;
}

const UploadFilesForm: React.FC<UploadFilesFormProps> = ({
  handleAddFiles,
  error,
  selectedTicker,
  setSelectedTicker,
  activeFiles,
  setActiveFiles,
  documentTypes,
  selectedDocumentType,
  setSelectedDocumentType
}) => {
  const classes = useStyles();

  const documentTypeOptions = useMemo(() => documentTypes.filter(d => d !== 'PRIVATE').map((d) => ({ value: d, label: d })), [
    documentTypes
  ]);

  const onPeriodChange = (id: string, newValue: string) => {
    const newFiles = updateFilePeriod(activeFiles, id, newValue);
    setActiveFiles(newFiles);
  };

  const onLanguageChange = (id: string, lang: string) => {
    const newFiles = updateFileLanguage(activeFiles, id, lang);
    setActiveFiles(newFiles);
  };

  const onRemoveFile: onRemoveFileType = (id) => {
    const newFiles = removeFileFromSelectedFiles(activeFiles, id);
    setActiveFiles(newFiles);
  };

  return (
    <>
      {!!error && (
        <Box p={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box className={classes.root}>
        <Box>
          <Box>
            <TickerDropdown
              onValueChange={(field, value) => setSelectedTicker(value)}
              selectedValue={selectedTicker}
            />
          </Box>
          <Box>
            <Select
              className={classes.field}
              placeholder={'Select Document Type'}
              options={documentTypeOptions}
              value={selectedDocumentType}
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedDocumentType(newValue);
                }
              }}
            />
          </Box>
          <Box>
            <Box className={classes.dragDropArea}>
              <img src={UploadFilesIcon} alt="upload" />
              <Box className={classes.dragDropLabel}>
                Drag & Drop your
                <span className={classes.dragDropLabelBold}>PDF files here</span>
              </Box>
              <Box mb={1} className={classes.dragDropLabelBold}>
                filename should be [QUARTER].pdf
              </Box>
              <Box className={classes.browseFilesLabel} onClick={handleAddFiles}>
                Browse Files
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <DataTable
            autoHeight={false}
            rows={activeFiles}
            columns={getFilesCols(onRemoveFile, onPeriodChange, onLanguageChange)}
            disableColumnMenu
            hideFooterPagination={true}
            hideFooter={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default UploadFilesForm;
