import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from './DialogHeader';
import useStyles, { DialogContent, DialogActions } from './style';
import { DialogProps } from './types';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';

const CustomizedDialogs: React.FC<DialogProps> = ({
  isOpen,
  handleClose,
  handleOk,
  title,
  saveButtonLabel = 'Save',
  closeButtonLabel = 'Close',
  hideDialogActions = false,
  width = 'md',
  minHeight = 'unset',
  customDialogActions,
  children,
  isLoading,
  isSaveDisabled = false
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth={width}
      className={classes.dialog}
      disableEnforceFocus={true}
    >
      {isLoading && (
        <>
          <Box className={classes.spinner}>
            <CircularProgress />
          </Box>
        </>
      )}
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent style={{ minHeight }} dividers>
        {children}
      </DialogContent>
      {!hideDialogActions && (
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={handleClose}
            color="primary"
            disabled={isLoading}
          >
            {closeButtonLabel}
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={handleOk}
            color="primary"
            disabled={isLoading || isSaveDisabled}
          >
            {saveButtonLabel}
          </Button>
        </DialogActions>
      )}
      {customDialogActions}
    </Dialog>
  );
};

export default CustomizedDialogs;
