import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'ui-kit';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { selectUser } from 'components/Home/state/selectors';
import useStyles from '../UploadViaLink/styles';
import CompanyIdentifierInput from '../UploadViaLink/CompanyIdentifierInput';
import { selectSelectedCompany } from '../state/selectors';
import InputBase from '@material-ui/core/InputBase';
import useUploadFileStyles from '../UploadFile/styles';
import { isEmpty, range } from 'lodash';
import { PeriodRangeRow } from './PeriodRangeRow';
import { PeriodRange } from '../UploadViaLink/types';
import { submitTranscriptIngest } from '../services';
import { errorMsgResolver } from '../utils';
import { notify } from 'store';
import { useAction } from '../hooks/useAction';
import { useMixpanel } from '../../../mixpanel/useMixpanel';
interface TranscriptIngestProps {
  isOpen: boolean;
  handleClose: () => void;
}

const getCurrentPeriod = () => {
  const today = new Date();
  const q = [1, 2, 3, 4];
  const currentQuarter = q[Math.floor(today.getMonth() / 3)];
  const year = today.getFullYear();
  return `${year}Q${currentQuarter}`;
};

const TranscriptIngestDialog: React.FC<TranscriptIngestProps> = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const selectedCompany = useSelector(selectSelectedCompany);
  const [numberOfPeriods, setNumberOfPeriods] = useState(1);
  const [periodsRange, setPeriodsRange] = useState<PeriodRange[]>([]);
  const uploadFilesClasses = useUploadFileStyles();
  const { resetLinksFormAction } = useAction();
  const myMixpanel = useMixpanel();

  const beforeClose = () => {
    resetLinksFormAction();
    handleClose();
  };

  const changePeriodRange = (index: number, newPeriodRange: PeriodRange) => {
    const newPeriods = [...periodsRange];
    newPeriods[index] = newPeriodRange;
    setPeriodsRange(newPeriods);
  };

  const deletePeriodRange = (index: number) => {
    periodsRange.splice(index, 1);
    const newPeriods = [...periodsRange];
    setPeriodsRange(newPeriods);
  };

  const addPeriodRange = () => {
    const newPeriods: PeriodRange[] = [];
    range(0, numberOfPeriods).forEach(() => {
      newPeriods.push({ start: getCurrentPeriod(), end: null });
    });
    const allPeriods = [...periodsRange, ...newPeriods];
    setPeriodsRange(allPeriods);
  };

  const submitTranscriptIngestion = async () => {
    setIsLoading(true);
    try {
      const response = await submitTranscriptIngest(selectedCompany.id, periodsRange);
      if (response) {
        beforeClose();
      }
      myMixpanel?.track('goldengate:submit_ingest_transcript_successfully', {
        companyId: selectedCompany.id,
        ticker: selectedCompany.ticker,
        cik: selectedCompany.cik,
        periodsRange
      });
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
      myMixpanel?.track('goldengate:submit_ingest_transcript_failed', {
        companyId: selectedCompany.id,
        ticker: selectedCompany.ticker,
        cik: selectedCompany.cik,
        periodsRange,
        error: errorMsgResolver(error)
      });
    } finally {
      setIsLoading(false);
    }
  };

  const dialogActionContent = (
    <>
      <DialogActions>
        <Box className={uploadFilesClasses.formActions}>
          <Box>
            <Button
              className={uploadFilesClasses.formPrimaryAction}
              variant="outlined"
              onClick={beforeClose}
              color="primary"
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              className={uploadFilesClasses.formPrimaryAction}
              variant="contained"
              onClick={submitTranscriptIngestion}
              color="primary"
              disabled={isLoading || isEmpty(selectedCompany.cik) || isEmpty(periodsRange)}
            >
              Submit
            </Button>
          </Box>
          <Box>
            <>
              <InputBase
                value={numberOfPeriods}
                onChange={(e) => setNumberOfPeriods(+e.target.value)}
                className={classes.linkInput}
                id="outlined-number"
                type="number"
              />
              <Button
                className={classes.addRowsButton}
                variant="contained"
                color="primary"
                onClick={addPeriodRange}
              >
                Add
              </Button>
            </>
          </Box>
        </Box>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      handleClose={beforeClose}
      isOpen={isOpen}
      hideDialogActions={true}
      isLoading={isLoading}
      title="Transcript Ingestion"
      width="md"
      minHeight="450px"
      customDialogActions={dialogActionContent}
    >
      <Box>
        <CompanyIdentifierInput identifierType={'capiqcompanyid'} />
        <Divider />
        {!!error && (
          <Box p={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {periodsRange.map((periodRange, index) => (
          <PeriodRangeRow
            key={`${index}`}
            periodRange={periodRange}
            onRemove={() => deletePeriodRange(index)}
            onChange={(newPeriod) => changePeriodRange(index, newPeriod)}
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default TranscriptIngestDialog;
