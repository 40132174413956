import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from 'components/Companies/state';
import { Dialog, OptionType } from 'ui-kit';
import { useAction } from '../hooks/useAction';
import { notify, TickerDropdown } from 'store';
import PrioritiesDropdown from '../../PrioritiesDropdown';
import useStyles from './style';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import { errorMsgResolver } from '../../Documents/utils';
import { modifyTickerPrioritise } from './services';

export type TickerPriority = {
  id?: number;
  ticker?: string;
  priority?: string;
  selectedOption?: OptionType;
};

const ModifyTickersPriority = () => {
  const styles = useStyles();
  const { triggerModifyTickerPriorityAction } = useAction();
  const isOpen = useSelector(Selectors.selectIsEditPrioritiesModalOpen);
  const [tickersList, setTickersList] = useState<TickerPriority[]>([{}]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setTickersList([{}]);
  }, [isOpen]);

  const onSave = async () => {
    const listOfModifiedTickers = tickersList
      .filter((tickerPriority) => Boolean(tickerPriority.ticker && tickerPriority.priority))
      .map((tickerPriority) => ({
        ticker: tickerPriority.ticker,
        priority: tickerPriority.priority
      }));

    if (isEmpty(listOfModifiedTickers)) {
      notify({
        message: errorMsgResolver('You need to fill at least one ticker priority'),
        severity: 'warning',
        open: true
      });
      return;
    }

    setLoading(true);

    try {
      const resp = await modifyTickerPrioritise(
        listOfModifiedTickers as { ticker: string; priority: string }[]
      );
      if (resp.status !== 200) {
        const errors = resp?.data?.failed_to_update;
        if (errors) {
          notify({
            message: `Failed to update ${errors?.length} out of ${
              listOfModifiedTickers?.length
            } reasons: ${errors?.map((error: { reason: string }) => error.reason)?.join(' | ')}`,
            severity: 'error',
            open: true
          });
        } else {
          notify({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            message: errorMsgResolver(resp.data.details),
            severity: 'error',
            open: true
          });
        }
      } else {
        notify({
          message: `Tickers Priorities was saved for ${listOfModifiedTickers.length} tickers`,
          severity: 'success',
          open: true
        });
        triggerModifyTickerPriorityAction(false);
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      width="sm"
      isOpen={isOpen}
      title="Modify Tickers Priorities"
      handleClose={() => triggerModifyTickerPriorityAction(false)}
      handleOk={onSave}
      isLoading={isLoading}
    >
      {tickersList.map((tickerItem, index) => (
        <div key={tickerItem.ticker} className={styles.prioritiesFormContainer}>
          <TickerDropdown
            onValueChange={(field, option) => {
              const newTickerList = [...tickersList];
              newTickerList[index].ticker = (option as OptionType).label;
              newTickerList[index].selectedOption = option as any;
              setTickersList(newTickerList);
            }}
            selectedValue={tickersList[index].selectedOption}
          />
          <PrioritiesDropdown
            onChange={(newPriority) => {
              const newTickerList = [...tickersList];
              newTickerList[index].priority = newPriority;
              setTickersList(newTickerList);
            }}
          />
        </div>
      ))}
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setTickersList([...tickersList, {}])}
        color="primary"
      >
        <AddIcon />
      </Button>
    </Dialog>
  );
};

export default ModifyTickersPriority;
