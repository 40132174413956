import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'index.css';
import App from 'App';
import { store } from 'store/store';
import { DaloopaTheme } from 'ui-kit';
import { ThemeProvider } from '@material-ui/core/styles';
import { MIXPANEL_CONFIG, MIXPANEL_TOKEN } from './config/config';
import { MixpanelProvider } from './mixpanel/MixpanelProvider';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={DaloopaTheme}>
          <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
            <App />
          </MixpanelProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
