import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9fafa',
    marginLeft: '0.5em',
    padding: '0.3em 1em',
    height: '2.5em'
  },
  searchField: {
    border: 'none',
    padding: '0 1em',
    width: '100%'
  }
}));
