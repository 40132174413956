import { createContext, Dispatch } from 'react';
import { noop } from 'react-select/src/utils';
import { dataTableInitialState, DataTableStateType } from './index';
import { ActionType } from './actionTypes';

const StateContext = createContext<{ state: DataTableStateType; dispatch: Dispatch<ActionType> }>({
  state: dataTableInitialState,
  dispatch: noop
});

export default StateContext;
