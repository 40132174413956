import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface AuthorizationGardProps {
  children: React.ReactElement;
  isAuthorized: boolean;
}

const AuthorizationGard: React.FC<AuthorizationGardProps> = ({ children, isAuthorized }) => {
  if (isAuthorized) {
    return children;
  }

  return (
    <Tooltip title="Insufficient permissions" arrow>
      <span>{children}</span>
    </Tooltip>
  );
};

export default AuthorizationGard;
