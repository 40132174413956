import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  appTitle: {
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Comfortaa'
  },
  accountIcon: {
    width: '40px',
    height: '40px'
  }
});
