import { NotificationInstance as RCNotificationInstance } from 'rc-notification/lib/Notification';
import RCNotification from 'rc-notification';

let EventBus: RCNotificationInstance | null = null;

const initInstance = () => {
  RCNotification.newInstance(
    {
      maxCount: 5
    },
    (instance) => (EventBus = instance)
  );
};

initInstance();

export const closeBus = (key: string | number) => {
  EventBus?.removeNotice(key);
};

export default EventBus;
