import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  primaryBtn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      boxShadow: 'none'
    },
    marginRight: 12
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    height: '30vh',
    justifyContent: 'space-around'
  },
  field: {
    width: '340px',
    '& > div': {
      height: '45px'
    }
  },
  checkboxInput: {
    width: '10.75em',
    margin: '0 10px'
  },
  fileName: {
    marginLeft: 12
  }
}));
