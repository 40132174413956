import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChipInput from 'material-ui-chip-input';
import { CompanyType, FormikType } from 'store';
import { ComboBox, OptionType } from 'ui-kit';
import useStyles from './style';
import { useSelector } from 'react-redux';
import { selectIndustries } from '../state/selectors';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { CompanyIdentifiersForm } from './CompanyIdentifiersForm';

interface ManageCompanyFormProps {
  formik: FormikType<CompanyType>;
  isEdit: boolean;
}

const ManageCompanyForm: React.FC<ManageCompanyFormProps> = ({ formik, isEdit }) => {
  const classes = useStyles();
  const industries = useSelector(selectIndustries);
  const industriesOptions: OptionType[] = useMemo(
    () => industries?.map((industry) => ({ label: industry.name, value: `${industry.id}` })),
    [industries]
  );
  const userEmailsChipsValue: string[] = useMemo(() => {
    if (formik.values.usersEmailsToAccessPrivateCompany) {
      return formik.values.usersEmailsToAccessPrivateCompany?.split(',');
    }
    return [];
  }, [formik.values.usersEmailsToAccessPrivateCompany]);

  return (
    <Box className={classes.form}>
      <Box mb="15px">
        <div className={classes.formHeader}>Please enter the company details</div>
      </Box>
      <Box className={classes.formGrid}>
        <Box className={classes.comboBoxInput}>
          <ComboBox
            name="industry"
            id="industry"
            placeholder="Select industry"
            value={formik.values.industry as OptionType}
            setFieldValue={(field, value) => {
              const selectedValue = value as OptionType;
              formik.setFieldValue('industry', selectedValue);
              const industryObject = industries.find(
                (industry) => industry.id === +selectedValue.value
              );
              formik.setFieldTouched('sector');
              formik.setFieldValue('sector', industryObject?.sector_name);
            }}
            options={industriesOptions}
            error={Boolean(formik.errors.industry && formik.touched.industry)}
            helperText={
              formik.touched.industry ? formik.errors.industry && 'Industry is required' : undefined
            }
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <TextField
            label="Sector"
            placeholder="Select industry to see Sector"
            className={classes.field}
            id="sector"
            disabled={true}
            InputLabelProps={{
              shrink: true
            }}
            value={formik.values.sector}
            name="sector"
            onChange={formik.handleChange}
            variant="outlined"
            error={Boolean(formik.errors.sector && formik.touched.sector)}
            helperText={formik.touched.sector && formik.errors.sector}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <TextField
            label="Ticker"
            placeholder="Company ticker, ex: AMZN"
            className={classes.field}
            id="ticker"
            InputLabelProps={{
              shrink: true
            }}
            value={formik.values.ticker}
            name="ticker"
            onChange={formik.handleChange}
            variant="outlined"
            error={Boolean(formik.errors.ticker && formik.touched.ticker)}
            helperText={formik.touched.ticker && formik.errors.ticker}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <TextField
            label="Company Name"
            placeholder="Enter Company Name"
            className={classes.field}
            id="name"
            InputLabelProps={{
              shrink: true
            }}
            value={formik.values.name}
            name="name"
            onChange={formik.handleChange}
            variant="outlined"
            error={Boolean(formik.errors.name && formik.touched.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>
        <CompanyIdentifiersForm formik={formik} isEdit={isEdit} />
        <Box />
        <Box className={classes.fieldContainer}>
          <FormControlLabel
            className={classes.checkboxInput}
            control={
              <Checkbox
                checked={formik.values.is_private}
                color="primary"
                name="is_private"
                onChange={formik.handleChange}
              />
            }
            label="is Private"
          />
          <Box className={classes.fieldContainer}>
            <FormControlLabel
              className={classes.checkboxInput}
              control={
                <Checkbox
                  checked={formik.values.is_abridged}
                  color="primary"
                  name="is_abridged"
                  onChange={formik.handleChange}
                />
              }
              label="is Abridged"
            />
          </Box>
          <FormControlLabel
            className={classes.checkboxInput}
            control={
              <Checkbox
                checked={formik.values.active}
                onChange={formik.handleChange}
                color="primary"
                name="active"
              />
            }
            label="is Active"
          />
        </Box>
        <Box className={classes.fieldContainer} justifyContent="space-between" display="flex">
          <FormLabel component="legend">Disabled</FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="disabled"
            value={formik.values.disabled}
            onChange={(event) => {
              formik.setFieldValue('disabled', event.currentTarget.value === 'true');
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Yes"
              labelPlacement="start"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="No"
              labelPlacement="start"
            />
          </RadioGroup>
        </Box>
        {formik.values.is_private && (
          <Box className={classes.fieldContainer}>
            <FormControlLabel
              className={classes.chipsInput}
              labelPlacement="top"
              control={
                <ChipInput
                  value={userEmailsChipsValue}
                  placeholder="Enter user emails here..."
                  onAdd={(chip) => {
                    const newEmails = [...userEmailsChipsValue, chip];
                    formik.setFieldValue('usersEmailsToAccessPrivateCompany', newEmails.join(','));
                  }}
                  onDelete={(chip) => {
                    const newEmails = [...userEmailsChipsValue].filter((email) => email !== chip);
                    formik.setFieldValue('usersEmailsToAccessPrivateCompany', newEmails.join(','));
                  }}
                />
              }
              label="Users emails To access this company"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageCompanyForm;
