import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    borderRadius: '20px',
    padding: '3px 10px',
    '& p': {
      fontSize: '10px'
    }
  }
}));
