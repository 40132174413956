import { format } from 'date-fns';
import React from 'react';
import { GridColumns } from '@material-ui/data-grid';
import { Button, Chip, styled } from '@material-ui/core';
import Badge from '../Badge/Badge';
import Box from '@material-ui/core/Box';
import AuthorizationGard from 'store/src/AuthorizationGard';
import RedoIcon from '@material-ui/icons/LoopOutlined';
import IconButton from '@material-ui/core/IconButton';

const ProgressBarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '10px'
});

const ErrorMessage = styled('span')({
  fontSize: 14,
  color: 'red'
});

export const getPreqinIngestionColDef = (
  onRerunAll: (id: number) => void,
  onRerunFailedOnly: (id: number) => void,
  canRerun: boolean
): GridColumns => [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 100
  },
  {
    field: 'project_name/registry_name',
    headerName: 'Project Name/Registry Name',
    minWidth: 300,
    resizable: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    renderCell: (params) => `${params.row.project_name}/${params.row.registry_name}`
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    renderCell: (params) => format(new Date(params.row?.created_at), 'dd MMM yyyy HH:mm'),
    minWidth: 150
  },
  {
    field: 'Status',
    headerName: 'Progress',
    renderCell: (params) => {
      const { ingested_count, ingesting_count, processed_count, failed_count } = params.row as {
        [key: string]: number;
      };

      if (params.row.task_status === 'FAILURE') {
        return <ErrorMessage>Failed to Ingest documents.</ErrorMessage>;
      }

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-assignment
      const totalCount = ingested_count + ingesting_count + processed_count + failed_count;

      if (totalCount === 0) {
        return <ErrorMessage>No Documents Found</ErrorMessage>;
      }

      return (
        <ProgressBarContainer>
          <Chip
            label={`${ingested_count + processed_count} Done`}
            color="secondary"
            variant="outlined"
          />
          <Chip label={`${ingesting_count} In Progress`} color="primary" variant="outlined" />
          <Chip label={`${failed_count} failed`} variant="outlined" />
        </ProgressBarContainer>
      );
    },
    minWidth: 350
  },
  {
    field: 'task_status',
    headerName: 'Status',
    renderCell: (params) => {
      const { task_status } = params.row;
      // eslint-disable-next-line
      return <Badge status={task_status?.toLowerCase()} />;
    },
    minWidth: 150
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params) => (
      <Box>
        <AuthorizationGard isAuthorized={canRerun}>
          <Button
            disabled={!canRerun}
            color="primary"
            variant="outlined"
            endIcon={<RedoIcon />}
            style={{ marginRight: '10px' }}
            onClick={() => onRerunFailedOnly(params.row.id)}
          >
            Failed
          </Button>
        </AuthorizationGard>
        <AuthorizationGard isAuthorized={canRerun}>
          <Button
            onClick={() => onRerunAll(params.row.id)}
            disabled={!canRerun}
            color="primary"
            variant="outlined"
            endIcon={<RedoIcon />}
          >
            All
          </Button>
        </AuthorizationGard>
      </Box>
    ),
    filterable: false,
    sortable: false,
    editable: false,
    minWidth: 250
  }
];
