import { Axios } from 'core';
import { PRIVATE_INGEST, PRIVATE_INGEST_EXPORT } from './endpoints';

export const reRunDocumentIngest = async (ingestionID: number): Promise<boolean> => {
  const reqBody = {
    id: ingestionID
  };
  return Axios.put(PRIVATE_INGEST, reqBody);
};

export const exportPrivateDocumentIngestion = async (queryParams: string): Promise<void> => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0].replace('-', '_');
  const response = await Axios.get<string>(`${PRIVATE_INGEST_EXPORT}${queryParams}`);
  const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `private_document_ingestion_${formattedDate}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
