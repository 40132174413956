import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { ComboBox } from 'ui-kit';
import { PeriodInput } from 'store';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { selectDocumentLinkAtIndex } from '../state/selectors';
import { useAction } from '../hooks/useAction';
import { FILLING_TYPES_OPTIONS } from './types';

interface DocumentLinkRowProps {
  index: number;
}

const DocumentLinkRow: React.FC<DocumentLinkRowProps> = ({ index }) => {
  const classes = useStyles();
  const entry = useSelector(selectDocumentLinkAtIndex(index));
  const { updateDocumentLinkData, removeDocumentLinkAction } = useAction();

  const handleTypeChange = (type: { value: string; label: string }) => {
    updateDocumentLinkData({ ...entry, type }, index);
  };

  const handlePeriodChange = (period: string) => {
    updateDocumentLinkData({ ...entry, period }, index);
  };

  const handleUrlChange = (url: string) => {
    updateDocumentLinkData({ ...entry, url }, index);
  };

  return (
    <Box key={entry.id} className={classes.inputsRow}>
      <PeriodInput
        onPeriodChange={(newValue) => handlePeriodChange(newValue)}
        value={entry.period ?? ''}
      />
      <ComboBox
        options={FILLING_TYPES_OPTIONS}
        name="type"
        setFieldValue={(field, value) => {
          handleTypeChange(value as { value: string; label: string });
        }}
        value={entry.type}
        containerStyles={{
          margin: '8px'
        }}
      />
      <TextField
        onChange={(e) => handleUrlChange(e.target.value)}
        value={entry.url}
        label="url"
        variant="outlined"
        name="url"
        required
        InputLabelProps={{
          shrink: true
        }}
        className={classes.urlInput}
        style={{ height: '32px' }}
      />
      <Box display="flex" alignItems="center" justifyContent="end">
        <CloseIcon onClick={() => removeDocumentLinkAction(index)} className={classes.closeIcon} />
      </Box>
    </Box>
  );
};

export default DocumentLinkRow;
