import React, { useRef, useState } from 'react';
import useAuthorization from '../../hooks/useAuthorization';
import { PermissionKeys } from '../../config/permission-keys';
import { AuthorizationGard, EntityGrid } from 'store';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import useStyles from '../Companies/CompaniesActions/styles';
import { getConfigurationColDef } from './constants';
import { ConfigurationModal } from './ConfigurationModal';
import { ConfigurationType } from './types';
import { EntityGridRef } from 'store/src/EntityGrid/types';

const Configurations = () => {
  const entityRef = useRef<EntityGridRef>(null);
  const canAddConfiguration = useAuthorization(PermissionKeys.CAN_ADD_CONFIGURATIONS);
  const canEditConfiguration = useAuthorization(PermissionKeys.CAN_EDIT_CONFIGURATIONS);
  const [isAddConfigurationModalOpen, setIsAddConfigurationModalOpen] = useState(false);
  const [activeConfiguration, setActiveConfiguration] = useState<ConfigurationType | undefined>(
    undefined
  );
  const classes = useStyles();

  const onEdit = (row: ConfigurationType) => {
    setActiveConfiguration(row);
    setIsAddConfigurationModalOpen(true);
  };

  const actions = (
    <Box display="flex">
      <AuthorizationGard isAuthorized={canAddConfiguration}>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          startIcon={<AddBoxOutlined />}
          onClick={() => setIsAddConfigurationModalOpen(true)}
          disabled={!canAddConfiguration}
        >
          Configuration
        </Button>
      </AuthorizationGard>
      <Box m={1} />
    </Box>
  );

  return (
    <>
      <ConfigurationModal
        activeConfiguration={activeConfiguration}
        isOpen={isAddConfigurationModalOpen}
        onClose={() => {
          setIsAddConfigurationModalOpen(false);
          setActiveConfiguration(undefined);
          if (entityRef?.current?.refresh) {
            entityRef.current.refresh();
          }
        }}
      />
      <EntityGrid
        ref={entityRef}
        actionContent={actions}
        entityName="configurations"
        columns={getConfigurationColDef(canEditConfiguration, onEdit)}
        rows={[]}
        searchableColumns={['key']}
        placeholder="Search by Config Key"
      />
    </>
  );
};

export default Configurations;
