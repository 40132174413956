import { Axios } from 'core';
import { UploadLinkEntry } from '../UploadViaLink/types';
import { format } from 'date-fns';
import { COMPANIES_WITH_SIMILAR_CIK, UPLOAD_LINKS } from '../endpoints';
import { getDateFromQuarter } from '../utils';

export function uploadLinks(
  jobName: string,
  companyId: string,
  cik: string,
  linkEntities: UploadLinkEntry[]
) {
  const reqBody = {
    name: jobName,
    entities: linkEntities?.map((entity) => ({
      filing_date: format(getDateFromQuarter(entity.period ?? ''), 'yyyy-MM-dd'),
      cik: cik?.toString(),
      company_id: +companyId,
      form_type: entity.type?.value,
      url: entity.url
    }))
  };
  return Axios.post(UPLOAD_LINKS, reqBody);
}
