import React from 'react';
import { GridCellParams } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import RedoIcon from '@material-ui/icons/LoopOutlined';
import StatusBadge from '../../Badge/Badge';

const STATUS_MAP: { [key: string]: 'success' | 'pending' | 'failure' } = {
  IN_PROGRESS: 'pending',
  PENDING: 'pending',
  COMPLETED: 'success',
  ERROR: 'failure'
};

export const getDocumentColDef = (
  reingestTranscript: (transcriptIngestID: number) => Promise<void>
) => [
  { field: 'id', headerName: 'ID', width: 150, filterable: false },
  { field: 'ticker', headerName: 'Ticker', width: 150, filterable: false },
  {
    field: 'cap_company_id',
    headerName: 'CapCompanyID',
    width: 180,
    filterable: false
  },
  {
    field: 'period_start',
    headerName: 'Period Start',
    width: 150,
    filterable: false
  },
  {
    field: 'period_end',
    headerName: 'Period End',
    width: 150,
    filterable: false
  },
  {
    field: 'total_ingested',
    headerName: 'Total Ingested',
    width: 180,
    filterable: false
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    filterable: false,
    renderCell: (params: GridCellParams) => <StatusBadge status={STATUS_MAP[params.row.status]} />
  },
  {
    field: 'error',
    headerName: 'Error',
    width: 180,
    filterable: false
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params: GridCellParams) => (
      <Button
        onClick={() => reingestTranscript(params.row.id)}
        color="primary"
        variant="outlined"
        endIcon={<RedoIcon />}
      >
        ReRun
      </Button>
    )
  }
];
