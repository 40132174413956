import React from 'react';
import AsyncSelect from 'react-select/async';
import useStyles from './styles';
import Box from '@material-ui/core/Box';
import { ComboBoxBaseProps, OptionType } from './types';

interface AsyncSelectProps extends ComboBoxBaseProps {
  loadOptions: (
    inputValue: string,
    callback: (options: ReadonlyArray<OptionType>) => void
  ) => Promise<ReadonlyArray<OptionType>> | undefined;
}

const AsyncComboBox: React.FC<AsyncSelectProps> = ({
  loadOptions,
  name,
  id,
  setFieldValue,
  value,
  error,
  helperText,
  isDisabled,
  placeholder,
  isMulti
}) => {
  const classes = useStyles();

  return (
    <div>
      <AsyncSelect
        className={`${classes.field} ${error && classes.errorField}`}
        cacheOptions
        defaultOptions
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onChange={(option) => setFieldValue(name, option)}
        loadOptions={loadOptions}
        isMulti={isMulti}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 1301 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
        menuPortalTarget={document.body}
      />
      <Box className={`${classes.helperText} ${error && classes.errorText}`}>{helperText}</Box>
    </div>
  );
};

export default AsyncComboBox;
