import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: {
      width: '100%'
    },
    errorField: {
      '& > div': {
        'border-color': '#f44336'
      }
    },
    helperText: {
      marginTop: '3px',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.033em',
      marginRight: '14px',
      fontSize: '10px'
    },
    errorText: {
      color: '#f44336'
    },
    field: {
      '&:nth-child(2)': {
        height: '45px'
      }
    }
  })
);
