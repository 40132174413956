import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'ui-kit';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { selectUser } from 'components/Home/state/selectors';
import useStyles from '../UploadFile/styles';
import AddRowsInput from './AddRowsInput';
import { useAction } from '../hooks/useAction';
import CompanyIdentifierInput from './CompanyIdentifierInput';
import DocumentLinks from './DocumentLinks';
import {
  selectSelectedCompany,
  selectDocumentLinks,
  selectError,
  selectIsLoading
} from '../state/selectors';
import { useMixpanel } from '../../../mixpanel/useMixpanel';

interface _UploadViaLinkProps {
  isOpen: boolean;
  handleClose: () => void;
}

const UploadViaLink: React.FC<_UploadViaLinkProps> = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const { resetLinksFormAction, submitUploadLinks } = useAction();
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);
  const selectedCompany = useSelector(selectSelectedCompany);
  const rows = useSelector(selectDocumentLinks);
  const user = useSelector(selectUser);
  const myMixpanel = useMixpanel();
  const beforeClose = () => {
    resetLinksFormAction();
    handleClose();
  };

  const dialogActionContent = (
    <>
      <DialogActions>
        <Box className={classes.formActions}>
          <Box>
            <Button
              className={classes.formPrimaryAction}
              variant="outlined"
              onClick={beforeClose}
              color="primary"
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              className={classes.formPrimaryAction}
              variant="contained"
              onClick={() =>
                submitUploadLinks(
                  {
                    ticker: selectedCompany?.ticker,
                    cik: selectedCompany?.cik,
                    companyId: selectedCompany?.id,
                    entries: rows,
                    user
                  },
                  myMixpanel
                )
              }
              color="primary"
              disabled={isLoading}
            >
              Submit
            </Button>
          </Box>
          <Box>
            <AddRowsInput />
          </Box>
        </Box>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      handleClose={beforeClose}
      isOpen={isOpen}
      hideDialogActions={true}
      isLoading={isLoading}
      title="Load Documents from sec.gov"
      width="md"
      minHeight="450px"
      customDialogActions={dialogActionContent}
    >
      <Box>
        <CompanyIdentifierInput identifierType={'cik'} />
        <Divider />
        {!!error && (
          <Box p={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <DocumentLinks />
      </Box>
    </Dialog>
  );
};

export default UploadViaLink;
