export const getAppLogoSize = (size: string) => {
  switch (size) {
    case 'lg':
      return '75px';
    case 'sm':
      return '25px';
    case 'md':
    default:
      return '50px';
  }
};
