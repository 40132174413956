import React from 'react';
import Chip from '@material-ui/core/Chip';

interface PriorityProps {
  priority: '0' | '1' | '2' | '3' | '4' | '5';
}

const PriorityLabels = {
  '0': {
    label: 'Critical'
  },
  '1': {
    // this is Highest - but for user readability
    label: 'High'
  },
  '2': {
    label: 'High'
  },
  '3': {
    label: 'Medium'
  },
  '4': {
    label: 'Low'
  },
  '5': {
    label: 'Lowest'
  }
};

const Priority: React.FC<PriorityProps> = ({ priority }) => {
  return <Chip label={PriorityLabels[priority].label} />;
};

export default Priority;
