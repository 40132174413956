import { format } from 'date-fns';
import React from 'react';
import { GridColumns } from '@material-ui/data-grid';
import TableRowActions from 'store/src/TableRowActions';

export const getConfigurationColDef = (
  canEdit: boolean,
  onEdit: (row: any) => void
): GridColumns => [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 100
  },
  {
    field: 'key',
    headerName: 'Config Key',
    minWidth: 250
  },
  {
    field: 'value',
    headerName: 'Config Value',
    minWidth: 250
  },
  {
    field: 'Created By',
    headerName: 'created_by',
    minWidth: 150
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    renderCell: (params) => format(new Date(params.row?.created_at), 'dd MMM yyyy HH:mm'),
    minWidth: 250
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params) => (
      <TableRowActions onEditRow={() => onEdit(params.row)} canEdit={canEdit} canDelete={false} />
    ),
    filterable: false,
    sortable: false,
    editable: false,
    minWidth: 150
  }
];
