import { Dialog } from 'ui-kit';
import { Box, Button, Chip, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import * as services from '../services';
import { notify } from 'store';
import { errorMsgResolver } from 'components/Documents/utils';
import useStyles from '../styles';
import DoneIcon from '@material-ui/icons/Done';

interface GenerateAPIKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GenerateAPIKeyModal = ({ isOpen, onClose }: GenerateAPIKeyModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [isEmailTouched, setEmailTouched] = useState<boolean>(false);
  const [length] = useState<number>(40);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setAPIKey] = useState<string>();
  const [isCopied, setIsCopied] = useState(false);
  const classes = useStyles();

  const generateAPIKey = async () => {
    setAPIKey(undefined);
    setIsLoading(true);
    setIsCopied(false);
    try {
      const { success, message, key } = await services.generateAPIKey({ email, length });
      if (success) {
        setAPIKey(key);
        notify({
          message: 'Generated API Key Successfully',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message,
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  const actionButton = (
    <DialogActions className={classes.actionWrapper}>
      <Button
        onClick={generateAPIKey}
        color="primary"
        disabled={isEmpty(email) && isEmpty(length)}
        variant={'contained'}
      >
        Generate API Key
      </Button>
    </DialogActions>
  );

  const copyAPIKey = async () => {
    if (apiKey) {
      await navigator.clipboard.writeText(apiKey);
      setIsCopied(true);
      notify({
        message: 'API Key Copied !',
        severity: 'info',
        open: true
      });
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={'Generate API Key'}
      handleClose={onClose}
      isLoading={isLoading}
      width={'sm'}
      hideDialogActions={true}
      customDialogActions={actionButton}
      minHeight={'200px'}
    >
      <Box className={classes.container}>
        <TextField
          label="Email"
          placeholder="Client Email Address"
          InputLabelProps={{
            shrink: true
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value?.trim())}
          variant="outlined"
          onBlur={() => setEmailTouched(true)}
          error={isEmpty(email) && isEmailTouched}
          helperText={isEmpty(email) && isEmailTouched ? "email address can't be empty" : undefined}
          type="email"
          className={classes.apiInputField}
        />
        {!!apiKey && (
          <Chip
            label={apiKey}
            variant="outlined"
            onClick={copyAPIKey}
            deleteIcon={isCopied ? <DoneIcon /> : undefined}
            onDelete={isCopied ? copyAPIKey : undefined}
          />
        )}
      </Box>
    </Dialog>
  );
};
