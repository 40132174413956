import { Axios } from 'core';
import { SUBMIT_PREQIN_INGESTION_ENDPOINT } from './endpoints';
import { PreqinIngestionEvent, PreqinReIngestionEvent } from './types';

export const ingestPreqinDocuments = async (requestPayload: PreqinIngestionEvent) => {
  return await Axios.post<PreqinIngestionEvent>(SUBMIT_PREQIN_INGESTION_ENDPOINT, requestPayload, {
    validateStatus: (status) => status < 500
  });
};

export const reingestPreqinDocuments = async (requestPayload: PreqinReIngestionEvent) => {
  return await Axios.put<PreqinIngestionEvent>(SUBMIT_PREQIN_INGESTION_ENDPOINT, requestPayload, {
    validateStatus: (status) => status < 500
  });
};
