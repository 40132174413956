import { GridColumns } from '@material-ui/data-grid';
import { format } from 'date-fns';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { IndustryModal } from './types';
import { Edit, GetApp } from '@material-ui/icons';
import { downloadIndustryModal } from './services';

const formatDate = (date: string) => format(new Date(date), 'dd MMM yyyy HH:mm');

export const getColumns = (onEditModel: (model: IndustryModal) => void): GridColumns => {
  return [
    { field: 'id', headerName: 'ID', width: 100, filterable: false, sortable: false },
    {
      field: 'industry',
      headerName: 'Sector',
      width: 300,
      filterable: false,
      sortable: false,
      resizable: true
    },
    {
      field: 'latestQuarter',
      headerName: 'Latest Quarter',
      width: 150,
      filterable: false,
      sortable: false
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      filterable: false,
      sortable: false
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => formatDate((params.row as IndustryModal)?.updatedAt)
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 100,
      filterable: true,
      sortable: false,
      type: 'boolean',
      editable: false
    },
    {
      field: 'uploadedBy',
      headerName: 'Uploaded By',
      width: 200,
      renderCell: (params) => (params.row as IndustryModal)?.uploadedBy?.email,
      filterable: false,
      sortable: false
    },
    {
      field: 'downloads',
      headerName: 'Downloads',
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            void downloadIndustryModal((params.row as IndustryModal)?.id);
          }}
        >
          <GetApp />
        </IconButton>
      ),
      filterable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'edit',
      headerName: 'Edit',
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            void onEditModel(params.row as IndustryModal);
          }}
        >
          <Edit />
        </IconButton>
      ),
      filterable: false,
      flex: 1,
      sortable: false
    }
  ];
};
