import { GridApi, GridColumns } from '@material-ui/data-grid';
import { format } from 'date-fns';
import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import useStyles from 'store/src/TableRowActions/styles';
import Apartment from '@material-ui/icons/Apartment';
import { makeStyles } from '@material-ui/core';
import RotateLeft from '@material-ui/icons/RotateLeft';
import { AccessMode, ProductType, User, UserStatus, UserTier, UserType } from './types';
import { OptionType } from 'ui-kit';
import Clear from '@material-ui/icons/Clear';
import Done from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { capitalize } from 'utils';

const useMyStyles = makeStyles((theme) => ({
  userCompanyAccesses: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  resetPassword: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light
  }
}));

const UserCompanyAccessesButton = ({ onAdd }: { onAdd: () => void }) => {
  const classes = useStyles();
  const myClasses = useMyStyles();
  return (
    <IconButton color="primary" component="span" className={classes.actionBtn}>
      <Apartment className={myClasses.userCompanyAccesses} onClick={onAdd} />
    </IconButton>
  );
};

const ResetPasswordButton = ({ onClick }: { onClick: () => void }) => {
  const classes = useStyles();
  const myClasses = useMyStyles();
  return (
    <IconButton color="primary" component="span" className={classes.actionBtn}>
      <RotateLeft className={myClasses.resetPassword} onClick={onClick} />
    </IconButton>
  );
};

const EditButton = ({ onClick }: { onClick: () => void }) => {
  const classes = useStyles();
  const myClasses = useMyStyles();
  return (
    <IconButton color="primary" component="span" className={classes.actionBtn}>
      <EditIcon className={myClasses.resetPassword} onClick={onClick} />
    </IconButton>
  );
};

const capitalizeProductType = (productType: string) => {
  return productType.split('_').map(capitalize).join(' ');
};

export const PRODUCT_TYPE_OPTIONS: OptionType[] = Object.values(ProductType).map((productType) => ({
  value: productType,
  label: capitalizeProductType(productType)
}));

export const USER_STATUS_OPTIONS: OptionType[] = Object.values(UserStatus).map(
  (marketplace_status) => ({
    value: marketplace_status,
    label: marketplace_status
  })
);

export const USER_TIER_OPTIONS: OptionType[] = Object.values(UserTier).map((userTier) => ({
  value: userTier,
  label: userTier
}));

export const USER_TYPE_OPTIONS: OptionType[] = Object.values(UserType).map((userType) => ({
  value: userType,
  label: userType
}));

export const ACCESS_MODE_OPTIONS: OptionType[] = Object.values(AccessMode).map((accessMode) => ({
  value: accessMode,
  label: accessMode
}));

export const getColumns = (
  openUserCompanyAccessesModal: (userId: string) => void,
  resetPassword: (email: string) => Promise<void>,
  editUser: (user: User) => void
): GridColumns => {
  return [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'email', headerName: 'Email', width: 270 },
    {
      field: 'tier',
      headerName: 'Tier',
      width: 100,
      sortable: false,
      filterable: false
    },
    {
      field: 'product_type',
      headerName: 'Product',
      width: 150,
      sortable: false,
      filterable: false
    },
    {
      field: 'user_type',
      headerName: 'User Type',
      width: 120,
      sortable: false,
      filterable: false
    },
    {
      field: 'marketplace_status',
      headerName: 'User Status',
      width: 120,
      sortable: false,
      filterable: false
    },
    {
      field: 'daily_download_limit',
      headerName: 'Daily Limit',
      width: 120,
      sortable: false,
      filterable: false
    },
    {
      field: 'total_download_limit',
      headerName: 'Total Limit',
      width: 120,
      sortable: false,
      filterable: false
    },
    {
      field: 'date_joined',
      headerName: 'Date Joined',
      width: 170,
      renderCell: (params) => format(new Date(params.row?.date_joined), 'dd MMM yyyy HH:mm')
    },
    {
      field: 'is_active',
      headerName: 'Active',
      width: 120,
      renderCell: (params) => (params.row?.is_active ? <Done /> : <Clear />)
    },
    {
      field: 'disabled_by',
      headerName: 'Disabled By',
      width: 150,
      renderCell: (params) => (params.row?.disabled_by as string) ?? '-'
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      width: 170,
      renderCell: (params) => (
        <Tooltip title={(params.row?.created_by as string) ?? '-'}>
          <span>{(params.row?.created_by as string) ?? '-'}</span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,

      renderCell: (params) => (
        <>
          <UserCompanyAccessesButton onAdd={() => openUserCompanyAccessesModal(params?.row.id)} />
          <ResetPasswordButton onClick={() => resetPassword(params?.row.email)} />
          <EditButton onClick={() => editUser(params?.row as User)} />
        </>
      ),
      filterable: false,
      sortable: false
    }
  ];
};
