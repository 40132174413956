import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  secondary: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main
    }
  }
}));
