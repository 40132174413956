import React from 'react';
import { AuthorizationGard } from 'store';
import { Button } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import useAuthorization from 'hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';

interface DocumentActionsProps {
  triggerUploadViaLink: () => void;
  triggerUploadFile: () => void;
}

const DocumentsActions: React.FC<DocumentActionsProps> = ({
  triggerUploadFile,
  triggerUploadViaLink
}) => {
  const classes = useStyles();
  const canUploadCrawlerJobs = useAuthorization(PermissionKeys.CAN_UPLOAD_CRAWLER_JOBS);
  const canUploadDocument = useAuthorization(PermissionKeys.CAN_UPLOAD_DOCUMENTS);

  return (
    <Box display="flex" flexDirection="row-reverse">
      <AuthorizationGard isAuthorized={canUploadDocument}>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={triggerUploadFile}
          disabled={!canUploadDocument}
        >
          Document
        </Button>
      </AuthorizationGard>
      <Box m={1} />
      <AuthorizationGard isAuthorized={canUploadCrawlerJobs}>
        <Button
          className={`${classes.root} ${classes.secondary}`}
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
          onClick={triggerUploadViaLink}
          disabled={!canUploadCrawlerJobs}
        >
          Crawler
        </Button>
      </AuthorizationGard>
    </Box>
  );
};

export default DocumentsActions;
