import React from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const NotAllowedError = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.errorTitle}>403</Box>
      <Box className={classes.errorDescription}>Access Denied</Box>
      <Box className={classes.errorDescription}>you dont have permission to see this page</Box>
    </Box>
  );
};

export default NotAllowedError;
