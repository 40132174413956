import React from 'react';
import { GridTitleProps } from '../types';
import Box from '@material-ui/core/Box';
import useStyles from './style';
import capitalize from 'lodash/capitalize';

const Title: React.FC<GridTitleProps> = ({ subTitle, title }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Box fontSize={28}>{capitalize(title)}</Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box width={10} height={10} borderRadius="50%" className={classes.greenDot} />
        <Box fontSize={12} className={classes.subTitle}>
          {subTitle}
        </Box>
      </Box>
    </Box>
  );
};

export default Title;
