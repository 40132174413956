import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'components/Home/state/selectors';

const useAuthorization = (actionKey: string) => {
  const user = useSelector(selectUser);

  return useMemo(() => {
    return Boolean(user?.permissions?.includes(actionKey));
  }, [user, actionKey]);
};

export default useAuthorization;
