import React, { ChangeEvent } from 'react';
import { SearchFieldProps } from './types';
import Box from '@material-ui/core/Box';
import useStyles from './style';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { debounce } from '@material-ui/core';

const SearchField: React.FC<SearchFieldProps> = ({
  onSearch,
  width = '20em',
  placeholder = 'Search...'
}) => {
  const classes = useStyles();

  const onChange = debounce((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onSearch(e.target.value);
  }, 250);

  return (
    <Box className={classes.root}>
      <Box>
        <SearchIcon />
      </Box>
      <Box width={width}>
        <InputBase onChange={onChange} className={classes.searchField} placeholder={placeholder} />
      </Box>
    </Box>
  );
};

export default SearchField;
