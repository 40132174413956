import {
  ActionTypes,
  closeCompanyFormActionType,
  deleteCompanyActionType,
  loadIndustriesActionType,
  openAddCompanyActionType,
  openEditCompanyActionType,
  openModifyTickerPriorityActionType,
  openModifyTickerPriorityCSVActionType,
  setRequestStatusActionType,
  submitAddCompanyActionType,
  submitEditCompanyActionType,
  triggerLoaderActionType,
  updateIndustriesActionType
} from './actionTypes';
import { DeleteCompanyRequestType, IndustryType } from '../types';
import { CompanyType, EditCompanyType } from 'store';
import { MixpanelContext } from '../../../mixpanel/mixpanelContext';

export function deleteCompanyAction(
  deleteCompanyRequest: DeleteCompanyRequestType
): deleteCompanyActionType {
  return {
    type: ActionTypes.DELETE_COMPANY,
    payload: deleteCompanyRequest
  };
}

export function triggerLoaderAction(isLoading: boolean): triggerLoaderActionType {
  return {
    type: ActionTypes.TRIGGER_LOADER,
    payload: isLoading
  };
}

export function openAddCompanyAction(): openAddCompanyActionType {
  return {
    type: ActionTypes.OPEN_ADD_COMPANY,
    payload: null
  };
}

export function triggerModifyTickerPriorityAction(
  isOpen: boolean
): openModifyTickerPriorityActionType {
  return {
    type: ActionTypes.OPEN_MODIFY_TICKER_PRIORITY,
    payload: isOpen
  };
}

export function triggerModifyTickerPriorityCSVAction(
  isOpen: boolean
): openModifyTickerPriorityCSVActionType {
  return {
    type: ActionTypes.OPEN_MODIFY_TICKER_PRIORITY_CSV,
    payload: isOpen
  };
}

export function openEditCompanyAction(toBeEditedCompany: CompanyType): openEditCompanyActionType {
  return {
    type: ActionTypes.OPEN_EDIT_COMPANY,
    payload: toBeEditedCompany
  };
}

export function closeCompanyFormAction(): closeCompanyFormActionType {
  return {
    type: ActionTypes.CLOSE_COMPANY_FORM,
    payload: null
  };
}

export function submitAddCompanyAction(
  company: CompanyType,
  mixpanel: MixpanelContext
): submitAddCompanyActionType {
  return {
    type: ActionTypes.SUBMIT_ADD_COMPANY,
    payload: { mixpanel, company }
  };
}

export function submitEditCompanyAction(
  company: EditCompanyType,
  mixpanel: MixpanelContext
): submitEditCompanyActionType {
  return {
    type: ActionTypes.SUBMIT_EDIT_COMPANY,
    payload: { company, mixpanel }
  };
}

export function loadIndustriesAction(): loadIndustriesActionType {
  return {
    type: ActionTypes.LOAD_INDUSTRIES,
    payload: null
  };
}

export function updateIndustriesAction(industries: IndustryType[]): updateIndustriesActionType {
  return {
    type: ActionTypes.UPDATE_INDUSTRIES,
    payload: industries
  };
}

export function setRequestStatus(
  payload: Partial<{ isAddSuccess: boolean; isEditSuccess: boolean }>
): setRequestStatusActionType {
  return {
    type: ActionTypes.SET_REQUEST_STATUS,
    payload
  };
}
