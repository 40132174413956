import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'column',
    height: '100%',
    paddingBottom: '7em'
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '22px 20px 0',
    backgroundColor: 'inherit',
    height: '50px',
    padding: '20px 15px',
    color: 'inherit',
    transition: '0.1s all',
    borderRadius: '5px'
  },
  menuTitle: {
    marginLeft: '11px'
  },
  menuItemLink: {
    textDecoration: 'none',
    backgroundColor: theme.palette.grey.A100,
    color: theme.palette.text.hint,
    borderRadius: '5px'
  }
}));
