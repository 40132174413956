import { Dialog } from 'ui-kit';
import { CompanyType, confirm, EntityGrid, notify } from 'store';
import { GridColumns } from '@material-ui/data-grid';
import {
  deleteAllUserCompanyAccess,
  deleteUserCompanyAccess,
  getUserCompanyAccesses
} from '../services';
import React, { Ref, useRef, useState } from 'react';
import { DeleteButton } from 'store/src/EntityGrid/Actions';
import { errorMsgResolver } from '../../Documents/utils';
import { CreateUserCompanyAccessesModel } from './CreateUserCompanyAccessesModel';
import { Button, makeStyles } from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import { UserCompanyAccesses } from '../types';
import { EntityGridRef, GetTableDataResponse } from 'store/src/EntityGrid/types';
import DeleteIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  unSubscribeBtn: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      boxShadow: 'none'
    },
    marginLeft: 8
  }
}));

interface UserCompanyAccessesModalProps {
  isOpen: boolean;
  handleClose: () => void;
  userId?: string;
}

const getColumns = (onDelete: (id: string) => void): GridColumns => {
  return [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'companyTicker',
      headerName: 'Company Ticker',
      flex: 1,
      renderCell: (params) => (params?.row.company as CompanyType)?.ticker
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
      renderCell: (params) => (params?.row.company as CompanyType)?.name
    },
    {
      field: 'accessMode',
      headerName: 'Access Mode',
      flex: 1,
      renderCell: (params) => (params?.row as UserCompanyAccesses)?.accessMode
    },
    {
      field: '',
      headerName: 'Action ',
      renderCell: (params) => <DeleteButton onDelete={() => onDelete(params.row.id)} />,
      filterable: false,
      flex: 1
    }
  ];
};

export const UserCompanyAccessesModal = ({
  isOpen,
  handleClose,
  userId
}: UserCompanyAccessesModalProps) => {
  const classes = useStyles();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const entityRef: Ref<EntityGridRef> = useRef<EntityGridRef>(null);
  const [response, setResponse] = useState<GetTableDataResponse>();

  const onDelete = (id: string) => {
    confirm('Delete Confirmation', 'Are you sure you want to delete user company access ?', () => {
      setIsLoading(true);
      deleteUserCompanyAccess(id)
        .then(({ success, error }) => {
          if (success) {
            entityRef.current?.refresh?.();
            notify({
              message: 'user company access deleted',
              severity: 'success',
              open: true
            });
          } else {
            notify({
              message: error,
              severity: 'error',
              open: true
            });
          }
        })
        .catch((error) => {
          notify({
            message: errorMsgResolver(error as string),
            severity: 'error',
            open: true
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const onUnSubscribeAll = () => {
    if (!userId) {
      return;
    }
    confirm(
      'UnSubscribe All Confirmation',
      'Are you sure you want to delete all user company accesses ?',
      () => {
        setIsLoading(true);
        deleteAllUserCompanyAccess(userId)
          .then(({ success, errors }) => {
            if (success) {
              entityRef.current?.refresh?.();
              notify({
                message: 'all user company accesses were deleted',
                severity: 'success',
                open: true
              });
            } else {
              notify({
                message: errors.join(','),
                severity: 'error',
                open: true
              });
            }
          })
          .catch((error) => {
            notify({
              message: errorMsgResolver(error as string),
              severity: 'error',
              open: true
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    );
  };

  const handleCloseCreateUserCompanyAccessModal = () => {
    entityRef?.current?.refresh?.();
    setIsCreateModalOpen(false);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={'User Company Accesses'}
      handleClose={handleClose}
      width={'lg'}
      hideDialogActions={true}
    >
      <CreateUserCompanyAccessesModel
        isOpen={isCreateModalOpen}
        handleClose={handleCloseCreateUserCompanyAccessModal}
        userId={userId}
      />
      <EntityGrid
        loading={isLoading}
        entityName={'User Company Accesses'}
        columns={getColumns(onDelete)}
        rows={[]}
        isGqlAPI={true}
        graphqlService={(page: number, size: number, filters: { [p: string]: string }) =>
          getUserCompanyAccesses(userId as string, filters)
        }
        pageSize={8}
        isServerHandled={false}
        placeholder={'Search by ticker'}
        searchableColumns={['ticker']}
        ref={entityRef}
        setResponse={setResponse}
        actionContent={
          <>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddBoxOutlined />}
              onClick={() => setIsCreateModalOpen(true)}
              disabled={!userId}
              className={classes.addBtn}
            >
              Add Company Access
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              disabled={response ? response?.totalCount === 0 : true}
              onClick={onUnSubscribeAll}
              className={classes.unSubscribeBtn}
            >
              UnSubscribe All
            </Button>
          </>
        }
      />
    </Dialog>
  );
};
