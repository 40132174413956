import React from 'react';
import Box from '@material-ui/core/Box';
import { Button, CircularProgress } from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import useStyles from './styles';
import useAuthorization from 'hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';
import { AuthorizationGard } from 'store';
import PrioritiesDropdown from '../../PrioritiesDropdown';

export interface CompaniesActionsPropTypes {
  onTickerPriorityChange: (priority: string) => void;
  triggerAddCompany?: () => void;
  triggerChangePriorities?: () => void;
  triggerExportPriorities?: () => void;
  isExporting: boolean;
  isExportingDisabled: boolean;
  triggerUpdatePrioritiesUsingCSV: () => void;
}

const CompaniesActions: React.FC<CompaniesActionsPropTypes> = ({
  triggerAddCompany,
  triggerChangePriorities,
  onTickerPriorityChange,
  triggerExportPriorities,
  isExporting,
  isExportingDisabled,
  triggerUpdatePrioritiesUsingCSV
}) => {
  const classes = useStyles();
  const canAddCompany = useAuthorization(PermissionKeys.CAN_ADD_COMPANIES);
  const canModifyTickerPriority = useAuthorization(PermissionKeys.CAN_CHANGE_TICKER_PRIORITY);

  return (
    <Box display="flex">
      <AuthorizationGard isAuthorized={canAddCompany}>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          startIcon={<AddBoxOutlined />}
          onClick={triggerAddCompany}
          disabled={!canAddCompany}
        >
          Company
        </Button>
      </AuthorizationGard>
      <Box m={1} />
      <AuthorizationGard isAuthorized={canModifyTickerPriority}>
        <Button
          className={classes.modifyPriorities}
          variant="contained"
          color="secondary"
          onClick={triggerChangePriorities}
          disabled={!canModifyTickerPriority}
        >
          Change Priorities
        </Button>
      </AuthorizationGard>
      <AuthorizationGard isAuthorized={canModifyTickerPriority}>
        <Button
          className={`${classes.modifyPriorities} ${classes.modifyPrioritiesUsingCSV}`}
          variant="contained"
          color="secondary"
          onClick={triggerUpdatePrioritiesUsingCSV}
          disabled={!canModifyTickerPriority}
        >
          Change Priorities Using CSV
        </Button>
      </AuthorizationGard>
      <Button
        className={classes.exportPriorities}
        variant="contained"
        color="secondary"
        onClick={triggerExportPriorities}
        disabled={isExporting || isExportingDisabled}
      >
        {isExporting && <CircularProgress />}
        Export Priorities
      </Button>
      <Box m={1} />
      <Box minWidth="250px" marginX="20px">
        <PrioritiesDropdown onChange={onTickerPriorityChange} />
      </Box>
      <Box m={1} />
    </Box>
  );
};

export default CompaniesActions;
