export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const toBase64File = (file: File) => {
  return toBase64(file);
};

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`);
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
};
