import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    flexGrow: 1,
    backgroundColor: '#F4F7FE',
    width: 'calc(100% - 350px)'
  },
  main: {
    display: 'flex',
    width: '100vw',
    minHeight: 'calc(100vh - 88px)'
  }
}));
