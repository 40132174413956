import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    height: '30%'
  },
  errorTitle: {
    fontSize: '8em',
    fontFamily: 'MONOSPACE',
    color: theme.palette.primary.main
  },
  errorDescription: {}
}));
