import { HomeStateType } from 'components/Home/types';
import { ActionType, ActionTypes } from './actionTypes';

const initialState: HomeStateType = {
  isLoading: true,
  user: null
};

export default (state: HomeStateType = initialState, action: ActionType): HomeStateType => {
  switch (action.type) {
    case ActionTypes.TRIGGER_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};
