export interface UploadLinkEntry {
  period?: string;
  type?: { value: string; label: string };
  url?: string;
  id?: string;
}

export const FILLING_TYPES = [
  'No type',
  '10-K/Q',
  '8-K',
  '6-K',
  '20-F',
  '40-F',
  'S-1',
  'S-3',
  'S-4',
  'F-1',
  'F-4',
  '20FR12B',
  '424B3',
  '10-12B',
  'PRIVATE',
  'TRANSCRIPT',
  'DRS'
];

export const FILLING_TYPES_OPTIONS = FILLING_TYPES.map((fillingType) => ({
  value: fillingType,
  label: fillingType
}));

export interface PeriodRange {
  start: string;
  end: string | null;
}
