import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    margin: '22px 20px 0',
    padding: '25px',
    backgroundColor: theme.palette.white.main,
    height: '100%'
  }
}));
