import { combineReducers } from 'redux';
import companiesReducer from 'components/Companies/state';
import globalReducer from 'components/Home/state';
import documentsReducer from 'components/Documents/state';

const rootReducer = combineReducers({
  global: globalReducer,
  company: companiesReducer,
  documents: documentsReducer
});

export default rootReducer;

export type RootStateType = ReturnType<typeof rootReducer>;
