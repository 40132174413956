import React, { useState, useEffect } from 'react';
import { ComboBox, Dialog } from 'ui-kit';
import { PeriodInput } from 'store';
import { DocumentType } from '../types';
import { Box, CircularProgress } from '@material-ui/core';
import { updatePeriod } from '../services';
import Alert from '@material-ui/lab/Alert';
import useStyles from './styles';
import { FILLING_TYPES_OPTIONS } from '../UploadViaLink/types';
import { errorMsgResolver } from '../utils';

interface EditDocumentProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDocument: DocumentType | undefined;
}

const EditDocument: React.FC<EditDocumentProps> = ({ onClose, isOpen, selectedDocument }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [documentType, setDocumentType] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = React.useState('');
  const classes = useStyles();

  useEffect(() => {
    setDocumentType(selectedDocument?.document_type ?? '');
  }, [selectedDocument]);

  useEffect(() => {
    setDocumentType(selectedDocument?.document_type ?? '');
  }, [selectedDocument]);

  const onPeriodChange = (newPeriod: string) => {
    setSelectedPeriod(newPeriod);
  };

  const handleEditDocument = async () => {
    try {
      setLoading(true);
      await updatePeriod(selectedDocument?.filing, selectedPeriod);
      onClose();
    } catch (e) {
      setError(errorMsgResolver(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      handleClose={onClose}
      handleOk={handleEditDocument}
      isOpen={isOpen}
      closeButtonLabel="Cancel"
      saveButtonLabel="Edit"
      title="Edit Document"
      width="xs"
    >
      <Box display="flex" justifyContent="center">
        {!!error && (
          <Box p={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {isLoading ? (
          <>
            <Box className={classes.spinner}>
              <CircularProgress />
            </Box>
          </>
        ) : (
          <Box className={classes.editDocumentForm}>
            <Box mb={3} display="flex" alignItems="center" justifyContent="center">
              <div>Period</div>
              <PeriodInput
                onPeriodChange={(newValue) => onPeriodChange(newValue)}
                value={selectedPeriod}
              />
            </Box>
            <Box mb={3} display="flex" alignItems="center" justifyContent="center">
              <div>Type</div>
              <Box width="9.375em">
                <ComboBox
                  options={FILLING_TYPES_OPTIONS}
                  name="type"
                  setFieldValue={(field, value) => {
                    const _value = value as { value: string; label: string };
                    setDocumentType(_value.value);
                  }}
                  value={{ value: documentType, label: documentType }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default EditDocument;
