import { put, call, all, takeLatest } from 'redux-saga/effects';
import { notify } from 'store';
import * as actionCreators from './actionCreator';
import * as services from './services';
import { UserType } from '../types';
import { ActionTypes } from './actionTypes';
import { errorMsgResolver } from '../../Documents/utils';

function* getUserRequest() {
  try {
    yield put(actionCreators.triggerLoading(true));
    const response: { data: UserType } = yield call(services.getUserInfo);
    yield put(actionCreators.updateUser(response.data));
  } catch (error) {
    notify({ message: errorMsgResolver(error), severity: 'error', open: true });
  } finally {
    yield put(actionCreators.triggerLoading(false));
  }
}

export default function* watcher() {
  yield all([takeLatest(ActionTypes.GET_USER, getUserRequest)]);
}
