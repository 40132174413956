import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  subTitle: {
    color: 'gray'
  },
  greenDot: {
    backgroundColor: 'lightgreen',
    marginRight: '0.3em'
  }
}));
