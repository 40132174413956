import { CompanyType } from 'store';

export enum GenerateStatus {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export type Log = {
  id: string;
  status: GenerateStatus;
  taskId: string;
  taskType: string;
  message: string;
  updatedAt: string;
};

export enum ModelType {
  finalized = 'finalized',
  clientView = 'clientView',
  annualReady = 'annualReady',
  updateReady = 'updateReady',
  reviewed = 'reviewed',
  apiSnapshot = 'apiSnapshot',
  qqqqFySnapshot = 'qqqqFySnapshot'
}

export type ModelTypeKeys = keyof typeof ModelType;

export type SnapshotModel = { [index in ModelType]: boolean } & {
  id: number;
  company: CompanyType;
  createdAt: string;
  apiSnapshotLog: Log;
  separateAnnualsLog: Log;
};

export interface PaginatedSnapshotModel {
  page: number;
  pageSize: number;
  totalObjects: number;
  totalPages: number;
  excelModels: SnapshotModel[];
}

export enum ModelSnapshotTypes {
  SEPARATE_ANNUALS = 'SEPARATE_ANNUALS',
  API_SNAPSHOT = 'API_SNAPSHOT'
}
