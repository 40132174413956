import { Axios } from 'core';
import { GENERATE_API_KEY } from 'components/APIKeys/endpoints';
import { APIKeyGenerateRequestPayload, APIKeyGenerateResponse } from 'components/APIKeys/types';

export const generateAPIKey = async (
  requestPayload: APIKeyGenerateRequestPayload
): Promise<APIKeyGenerateResponse> => {
  const res = await Axios.post<APIKeyGenerateResponse>(GENERATE_API_KEY, requestPayload, {
    validateStatus: (status) => status < 500
  });
  return { success: res.status === 200, key: res.data.key, message: res.data.message };
};

export const deleteAPIKey = async (id: number): Promise<boolean> => {
  const res = await Axios.delete(`${GENERATE_API_KEY}?id=${id}`, {
    data: { id }
  });
  return res.status === 204;
};
