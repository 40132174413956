import { ApolloClient, InMemoryCache, from, ServerError } from '@apollo/client';
import history from '../History';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import {
  ACCESS_TOKEN_COOKIE_NAME,
  AUTHENTICATION_REQUIRED_ERROR,
  CSRFTOKEN,
  ERROR_DECODING_SIGNATURE,
  TOKEN_EXPIRATION_ERROR
} from '../constants';
import { logout, USE_SSO_LOGIN } from '../auth.utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getUserInfo = () => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  if (accessToken) return parseJwt(accessToken);
  return null;
};

export const getUserEmail = () => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  if (accessToken) {
    const payload: {
      email: string;
      exp: number;
    } = parseJwt(accessToken);
    return payload.email;
  }
  return null;
};

const getHeaders = () => {
  const csrfToken = Cookies.get(CSRFTOKEN);
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const headers: { 'X-CSRFToken'?: string; Authorization?: string } = {};

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  if (!USE_SSO_LOGIN && accessToken) {
    headers.Authorization = `JWT ${accessToken}`;
  }

  return headers;
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...getHeaders()
    }
  };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (
        message === TOKEN_EXPIRATION_ERROR ||
        message === AUTHENTICATION_REQUIRED_ERROR ||
        message === ERROR_DECODING_SIGNATURE
      ) {
        logout();
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    if (networkError.name === 'ServerError' || networkError.name === 'ServerParseError') {
      const code = (networkError as ServerError).statusCode;

      switch (code) {
        case 404:
          history.push('/404');
          break;
        case 401:
          history.push('/401');
          break;
        case 403:
          history.push('/403');
          break;
        case 500:
          history.push('/500');
          break;
      }
    }
  }
});

const link = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_URL + '/gql',
  headers: getHeaders(),
  credentials: 'include'
});

const backendUrl = new URL(process.env.REACT_APP_BACKEND_URL ?? '');

const DELAY = 30 * 1000;

const wsLink = new WebSocketLink({
  uri: `ws${backendUrl.host.includes('daloopa.com') ? 's' : ''}://${backendUrl.host}/subscriptions`,
  options: {
    reconnect: true,
    lazy: true,
    timeout: DELAY,
    minTimeout: DELAY,
    inactivityTimeout: DELAY
  }
});

// @ts-ignore
const httpLinks = from([errorLink, authLink.concat(link)]);

const appLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  // @ts-ignore
  wsLink,
  httpLinks
);

const client = new ApolloClient({
  // @ts-ignore
  link: appLink,
  cache: new InMemoryCache(),
  credentials: 'include'
});

export default client;
