import * as Yup from 'yup';
import { OptionType } from 'ui-kit';
import { CIK, ISIN } from '../constants';
import { Companyidentifier } from 'store';
import { get } from 'lodash';

type formType = {
  value: { is_private: boolean };
};
const validateIdentifiers = (identifiers: Companyidentifier[]) => {
  return identifiers.some((identifier) => [CIK, ISIN].includes(identifier.identifier_type));
};

export const CompanyFormSchema = Yup.object().shape({
  ticker: Yup.string().required(),
  name: Yup.string().required(),
  industry: Yup.object().shape({
    label: Yup.string().required()
  }),
  sector: Yup.string().required(),
  active: Yup.boolean(),
  is_private: Yup.boolean(),
  is_abridged: Yup.boolean(),
  identifiers: Yup.array()
    .of(
      Yup.object().shape({
        identifier_type: Yup.string().required(),
        identifier_value: Yup.string().required()
      })
    )
    .test({
      name: 'containsCIKOrISIN',
      message: 'CIK or ISIN identifier is required',
      test: (identifiers, context) => {
        const forms = get(context, 'from') as formType[];
        if (!forms) {
          return true;
        }
        return forms[0]?.value?.is_private ? true : validateIdentifiers(identifiers as any[]);
      }
    })
});

export interface CompanyFormType {
  ticker?: string;
  name?: string;
  cik?: string;
  identifierValue?: string;
  identifierType?: OptionType;
  industry?: OptionType;
  sector?: string;
  industry_fk?: number;
}
