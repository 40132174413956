import { ActionType, ActionTypes } from './actionTypes';
import { DocumentsStateType } from '../types';
import { FILLING_TYPES_OPTIONS } from '../UploadViaLink/types';
import { v4 as uuid } from 'uuid';

const initialState: DocumentsStateType = {
  selectedCompany: { ticker: '', cik: '', id: '' },
  documentLinks: [
    {
      period: '',
      type: FILLING_TYPES_OPTIONS[0],
      url: '',
      id: uuid()
    }
  ],
  isLoading: false,
  error: ''
};

export default (
  state: DocumentsStateType = initialState,
  action: ActionType
): DocumentsStateType => {
  switch (action.type) {
    case ActionTypes.UPDATE_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      };
    case ActionTypes.ADD_DOCUMENT_LINKS:
      const existingLinks = [...state.documentLinks];
      const addedLinks = [];
      for (let i = 0; i < action.payload; i++) {
        addedLinks.push({
          period: '',
          type: FILLING_TYPES_OPTIONS[0],
          url: '',
          id: uuid()
        });
      }
      return {
        ...state,
        documentLinks: [...existingLinks, ...addedLinks]
      };
    case ActionTypes.REMOVE_DOCUMENT_LINK:
      const newLinks = [...state.documentLinks];
      newLinks.splice(action.payload, 1);
      return {
        ...state,
        documentLinks: newLinks
      };
    case ActionTypes.UPDATE_DOCUMENT_LINK_ENTITY:
      const updatedLinks = [...state.documentLinks];
      updatedLinks[action.payload.index] = action.payload.data;
      return {
        ...state,
        documentLinks: updatedLinks
      };
    case ActionTypes.RESET_LINKS:
      return {
        ...state,
        documentLinks: [],
        selectedCompany: { ticker: '', cik: '', id: '' }
      };
    case ActionTypes.TRIGGER_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};
