import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  deleteIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    backgroundColor: theme.palette.error.light,
    padding: '6px',
    color: theme.palette.error.main
  },
  editIcon: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    padding: '6px',
    width: '30px',
    height: '30px',
    borderRadius: '5px'
  },
  actionBtn: {
    '&.Mui-disabled': {
      opacity: 0.5
    }
  }
}));
