import React, { useState } from 'react';
import { ComboBox } from 'ui-kit';

const PriorityOptions = [
  { value: '0', label: 'Critical' },
  // this is Highest - but for user readability
  { value: '1', label: 'High' },
  { value: '3', label: 'Medium' },
  { value: '4', label: 'Low' }
];

interface PrioritiesDropdownProps {
  onChange: (newPriority: string) => void;
}

const PrioritiesDropdown: React.FC<PrioritiesDropdownProps> = ({ onChange }) => {
  const [selectedPriority, setSelectedPriority] = useState<{ value: string; label: string }>();
  return (
    <ComboBox
      name="priority"
      placeholder="Select Ticker Priority"
      value={selectedPriority}
      setFieldValue={(field, value) => {
        setSelectedPriority(value as any);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onChange((value as any).value);
      }}
      options={PriorityOptions}
    />
  );
};

export default PrioritiesDropdown;
