import React from 'react';
import Box from '@material-ui/core/Box';

import useStyles from './style';

export const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={1} className={classes.root}>
      @ 2021 Daloopa, Inc. Privacy Policy / Terms of service
    </Box>
  );
};
