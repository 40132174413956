import React, { useLayoutEffect } from 'react';
import { USE_SSO_LOGIN } from '../auth.utils';

const getLastVisitedPath = () => {
  const currentPath = window.location.pathname;
  const currentSearch = window.location.search;

  return encodeURIComponent(`${currentPath}${currentSearch}`);
};

export const redirectToSSOUrl = () => {
  if (window && process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL) {
    const port = window.location.port;
    let fullDomainUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (Boolean(port)) {
      fullDomainUrl = `${fullDomainUrl}:${port}`;
    }
    const statePath = `${fullDomainUrl}${getLastVisitedPath()}`;
    window.location.href = `${process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URL}&state=${encodeURI(
      statePath
    )}`;
  }
};

const redirectToLoginUrl = () => {
  if (USE_SSO_LOGIN) {
    redirectToSSOUrl();
  } else {
    window.location.assign('/auth/login');
  }
};

export const SSORedirect = () => {
  useLayoutEffect(() => {
    redirectToSSOUrl();
  }, []);
  return <></>;
};

export { redirectToLoginUrl };
