import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Statuses, Base64Utils, notify } from 'store';
import { Dialog, OptionType } from 'ui-kit';

import { FileType, FileUploadStatuses } from './types';
import { getPrivateDocumentTypes, uploadDocument } from '../services';
import { processSelectedFiles } from '../utils';
import UploadFilesForm from './UploadFilesForm';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import { useMixpanel } from '../../../mixpanel/useMixpanel';

interface UploadFileProps {
  isOpen: boolean;
  handleClose: () => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ isOpen, handleClose }) => {
  const fileChooser = useRef<HTMLInputElement>(null);
  const [ticker, setTicker] = useState<OptionType | undefined>(undefined);
  const [files, setFiles] = useState<FileType[]>([]);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<string[]>([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState<OptionType | undefined>(
    undefined
  );
  const classes = useStyles();
  const myMixpanel = useMixpanel();

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getPrivateDocumentTypes().then((_documentTypes) => {
        setDocumentTypes(_documentTypes);
        setLoading(false);
      });
    }
  }, [isOpen]);

  const handleAddFiles = () => {
    fileChooser?.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    readFiles(Array.from(event?.target?.files ?? []));
  };

  const handleResetForm = () => {
    setFiles([]);
    setError('');
    setTicker(undefined);
    setSelectedDocumentType(undefined);
  };

  const closeForm = () => {
    handleResetForm();
    handleClose();
  };

  const readFiles = (newSelectedFiles: File[]) => {
    const processedFiles = processSelectedFiles(files, newSelectedFiles);
    setFiles([...processedFiles]);
  };

  const changeFileStatus = (targetFile: FileType, status: string) => {
    const newFiles = [...files];
    const index = newFiles.findIndex((file) => file.id === targetFile.id);
    newFiles[index].status = status;
    setFiles(newFiles);
  };

  const onSubmit = () => {
    if (!ticker) {
      setError('Ticker is a required field');
      return;
    }
    if (!selectedDocumentType) {
      setError('Document Type must be set');
      return;
    }
    if (!files || files?.length === 0) {
      setError('Please choose files to be uploaded');
    }

    for (const file of files) {
      if (!file.period) {
        setError('Please select the period for all files in the list');
        return;
      }
    }

    setError('');
    setLoading(true);
    let lengthOfFiles = files?.length;
    let numberOfFailedUpload = 0;

    for (const file of files) {
      if (!file || !file.file || file.status === FileUploadStatuses.Succeeded) {
        continue;
      }
      Base64Utils.toBase64File(file.file).then((base64) => {
        uploadDocument({
          period: file.period,
          content: base64 as string,
          company_id: parseInt(ticker.value),
          filing_type: null,
          language_code: file.language ?? 'en-us',
          document_type: selectedDocumentType.value
        })
          .then(() => {
            changeFileStatus(file, Statuses.Success);
            myMixpanel?.track('goldengate:upload_pdf_successful', {
              period: file.period,
              company_id: parseInt(ticker.value),
              ticker: ticker.label,
              document_type: selectedDocumentType.value,
              file_count: lengthOfFiles
            });
          })
          .catch(() => {
            changeFileStatus(file, Statuses.Failed);
            numberOfFailedUpload++;
            myMixpanel?.track('goldengate:upload_pdf_failed', {
              period: file.period,
              company_id: parseInt(ticker.value),
              ticker: ticker.label,
              document_type: selectedDocumentType.value
            });
          })
          .finally(() => {
            lengthOfFiles--;
            if (lengthOfFiles === 0) {
              setLoading(false);
              if (numberOfFailedUpload === 0) {
                closeForm();
                notify({
                  message: 'All files uploaded successfully',
                  open: true,
                  severity: 'success'
                });
              } else {
                // TODO Reporting needed for capturing failures
                notify({
                  message: `Failed to upload ${numberOfFailedUpload} files, please try again`,
                  open: true,
                  severity: 'error'
                });
              }
            }
          });
      });
      changeFileStatus(file, Statuses.InProgress);
    }
  };

  const dialogActionContent = (
    <>
      <DialogActions>
        <Box className={classes.formActions}>
          <Box>
            <Button
              className={classes.formPrimaryAction}
              variant="outlined"
              onClick={closeForm}
              color="primary"
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              className={classes.formPrimaryAction}
              variant="contained"
              onClick={onSubmit}
              color="primary"
              disabled={isLoading || files?.length === 0}
            >
              Upload
            </Button>
          </Box>
          <Box>
            <Button
              className={classes.formSecondaryAction}
              variant="outlined"
              onClick={handleResetForm}
              color="primary"
              disabled={isLoading}
            >
              Reset Form
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </>
  );

  return (
    <>
      <input
        onChange={handleFileChange}
        multiple
        value={[]}
        accept="image/*,application/pdf"
        ref={fileChooser}
        type="file"
        style={{ display: 'none' }}
      />
      <Dialog
        handleClose={closeForm}
        isOpen={isOpen}
        closeButtonLabel="Cancel"
        saveButtonLabel="Upload "
        title="Upload File"
        width="md"
        hideDialogActions={true}
        customDialogActions={dialogActionContent}
        isLoading={isLoading}
      >
        <UploadFilesForm
          error={error}
          handleAddFiles={handleAddFiles}
          activeFiles={files}
          setActiveFiles={setFiles}
          setSelectedTicker={(ticker) => setTicker(ticker as OptionType)}
          selectedTicker={ticker}
          documentTypes={documentTypes}
          selectedDocumentType={selectedDocumentType}
          setSelectedDocumentType={setSelectedDocumentType}
        />
      </Dialog>
    </>
  );
};

export default UploadFile;
