import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DialogActions } from '@material-ui/core';

export const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    '& > button': {
      borderRadius: '5px',
      height: '40px',
      minWidth: '166px'
    }
  }
}))(DialogActions);
