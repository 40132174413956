import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import { selectSelectedCompany } from 'components/Documents/state/selectors';
import { useAction } from 'components/Documents/hooks/useAction';
import useStyles from './styles';
import { OptionType } from 'ui-kit';
import { CompanyType, TickerDropdown } from 'store';

interface CompanyIdentifierInputProps {
  identifierType: 'cik' | 'capiqcompanyid';
}

const CompanyIdentifierInput: React.FC<CompanyIdentifierInputProps> = ({ identifierType }) => {
  const classes = useStyles();
  const [ticker, setTicker] = useState<OptionType | undefined>(undefined);
  const companyCik = useSelector(selectSelectedCompany);
  const { updateSelectedCompanyAction } = useAction();

  const onTickerChange = (ticker: OptionType | OptionType[] | undefined) => {
    setTicker(ticker as OptionType);
    const selectedCompany = ticker as { id: string; label: string; value: string };
    updateSelectedCompanyAction(selectedCompany.label, selectedCompany.value, selectedCompany.id);
  };

  const filterTickers = (company: CompanyType) => {
    return company.identifiers.some(
      (identifier) => identifier.identifier_type.toLowerCase() === identifierType
    );
  };

  return (
    <Box className={classes.cikContainer}>
      <InputBase
        className={classes.linkInput}
        required
        value={companyCik?.cik ?? ''}
        placeholder={`Company ${identifierType.toUpperCase()}`}
        onChange={(e) => updateSelectedCompanyAction('', e.target.value, '')}
      />
      <Box>or find it by ticker</Box>
      <TickerDropdown
        onValueChange={(field, value) => onTickerChange(value)}
        selectedValue={ticker}
        valueFieldKey={identifierType}
        filterOptions={filterTickers}
      />
    </Box>
  );
};

export default CompanyIdentifierInput;
