import React from 'react';
import { getUserEmail } from 'core';
import mixpanel, { Config } from 'mixpanel-browser';
import { ProviderProps, useMemo } from 'react';
import { MixpanelContext, mixpanelContext } from './mixpanelContext';

export interface MixpanelProviderProps extends Omit<ProviderProps<MixpanelContext>, 'value'> {
  config?: Partial<Config>;
  token?: string;
}

export const MixpanelProvider = ({ children, config: _config, token }: MixpanelProviderProps) => {
  const config = useMemo(
    () => ({
      track_pageview: false,
      ..._config
    }),
    [_config]
  );
  const email = getUserEmail();

  const context = useMemo(() => {
    if (!token) {
      return;
    }
    mixpanel.init(token, config);
    if (email) {
      mixpanel.identify(email);
      mixpanel.people.set({
        $email: email,
        $last_login: new Date()
      });
    }
    return mixpanel;
  }, [config, token, email]);

  return <mixpanelContext.Provider value={context}>{children}</mixpanelContext.Provider>;
};
