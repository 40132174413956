import React from 'react';
import { GridCellParams } from '@material-ui/data-grid';
import { format, intervalToDuration, formatDuration } from 'date-fns';
import StatusBadge from '../Badge/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Link } from '@material-ui/core';
import RotateLeft from '@material-ui/icons/RotateLeft';

const STATUS_MAP: { [key: string]: 'success' | 'pending' | 'failure' } = {
  IN_PROGRESS: 'pending',
  PENDING: 'pending',
  COMPLETED: 'success',
  ERROR: 'failure'
};

const ReRunButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton color="primary" component="span">
      <RotateLeft onClick={onClick} />
    </IconButton>
  );
};

const getDaloopaDomain = (prefix: string) => {
  if (!process.env.REACT_APP_BACKEND_URL) {
    return '#';
  }
  const backendURl = process.env.REACT_APP_BACKEND_URL;
  const parsedUrl = new URL(backendURl);
  const hostParts = parsedUrl.host.split('.');
  let host = parsedUrl.host;
  if (hostParts.length > 3) {
    host = hostParts.slice(1).join('.');
    return `${parsedUrl.protocol}//${prefix}.${host}`;
  }
  return `${parsedUrl.protocol}//${host}`;
};
const getPrimedDocumentURl = (documentId: number, companyId: number) => {
  const daloopaDomain = getDaloopaDomain('app');
  return `${daloopaDomain}/primed_document?document_id=${documentId}&company_id=${companyId}&show_navigation=true`;
};

const getCeleryUrl = (taskId: number) => {
  const daloopaDomain = getDaloopaDomain('celery-flower');
  return `${daloopaDomain}/task/${taskId}`;
};

export const getPrivateDocumentIngestionColDef = (reIngest: (id: number) => Promise<void>) => [
  { field: 'company_ticker', headerName: 'Ticker', width: 150, filterable: false, sortable: false },
  {
    field: 'user__email',
    headerName: 'User',
    width: 150,
    filterable: true,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Tooltip title={params.row?.user__email ?? '-'} arrow>
        <span>{params.row?.user__email ?? '-'}</span>
      </Tooltip>
    )
  },
  {
    field: 'period',
    headerName: 'Period',
    width: 120,
    filterable: true,
    sortable: true
  },
  {
    field: 'duration',
    headerName: 'Duration',
    width: 200,
    filterable: false,
    sortable: true,
    renderCell: (params: GridCellParams) => {
      const duration = intervalToDuration({ start: 0, end: params.row.duration * 1000 });
      return formatDuration(duration);
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params: GridCellParams) => <StatusBadge status={STATUS_MAP[params.row.status]} />,
    filterable: true,
    sortable: false,
    type: 'singleSelect',
    valueOptions: Object.keys(STATUS_MAP)
  },
  {
    field: 'ready_for_tagging',
    headerName: 'Ready For Tagging',
    width: 200,
    type: 'boolean',
    filterable: true,
    sortable: false
  },

  {
    field: 'file_size',
    headerName: 'File Size',
    width: 130,
    filterable: false,
    sortable: true,
    renderCell: (params: GridCellParams) => {
      const fileSize = parseFloat(`${params.row.file_size / (1000 * 1000)}`).toFixed(2);
      return `${fileSize} MB`;
    }
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 150,
    filterable: false,
    sortable: true,
    renderCell: (params: GridCellParams) =>
      format(new Date(params.row?.created_at), 'dd MMM yyyy HH:mm')
  },
  {
    field: 'Error',
    headerName: 'Error',
    width: 230,
    filterable: false,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Tooltip title={params.row?.error ?? '-'} arrow>
        <span>{params.row?.error ?? '-'}</span>
      </Tooltip>
    )
  },
  {
    field: 'document_type',
    headerName: 'Document Type',
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <Tooltip title={params.row?.document?.document_type ?? '-'} arrow>
        <Link
          href={getPrimedDocumentURl(params.row.document.id, params.row.company_id)}
          target={'_blank'}
        >
          {params.row?.document?.document_type}
        </Link>
      </Tooltip>
    )
  },
  {
    field: 'task_id',
    headerName: 'Task ID',
    width: 170,
    filterable: false,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Link href={getCeleryUrl(params.row.task_id)} target={'_blank'}>
        {params.row.task_id}
      </Link>
    )
  },
  {
    field: 'step',
    headerName: 'Step',
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <Tooltip title={params.row.step} arrow>
        <span>{params.row.step}</span>
      </Tooltip>
    )
  },
  {
    field: 'id',
    headerName: 'Id',
    width: 100,
    sortable: false,
    filterable: false
  },
  {
    field: 'reingest',
    headerName: 'Reingest',
    width: 120,
    filterable: false,
    sortable: false,
    renderCell: (params: GridCellParams) => <ReRunButton onClick={() => reIngest(params?.row.id)} />
  }
];
